import React, { useEffect, useState } from 'react'
import { BulkUpdateFlowStateType } from './BulkAutoUpdateService'
import {
  Row,
  Col,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { BulkUpdateContextType } from './BulkUpdateContext.type'
import ServiceImg from '../assets/icon-service'
import SiteImg from '../assets/icon-site'
import ReviewImg from '../assets/icon-review'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const BulkAutoUpdateBreadcrumbContainer = styled.div`
  padding: 1em;
  width: 70%;
`

const Progress = styled(Col)`
  height: 3em;
  justify-content: center;
  padding: 0;
  flex: 4;
`

const ProgressBar = styled.div<{active: boolean}>`
  height: 10px;
  border-bottom: 5px solid ${props => props.active ? '#252223' : '#ddd'};
  width: 100%;
`

const DEFAULT_ICON_COLOUR = '#231f20'

const BulkUpdateStageButton = styled.button<{active: boolean, name: string, currentState: string}>`
  border-radius: 50%;
  padding: 0;
  background: none;
  z-index: 2;
  width: 5em;
  height: 5em;
  border: ${props =>
    props.name === props.currentState
      ? `2px solid ${props.theme.colors.danger}`
      : 'none'};
  :hover {
    cursor: pointer;
    background: none;
    border: 2px solid ${props => props.active ? props.theme.colors.danger : props.theme.colors.primary};
    path {
      fill: ${DEFAULT_ICON_COLOUR};
      stroke: ${DEFAULT_ICON_COLOUR};
    }
  }
  path {
    fill: ${props => props.active ? DEFAULT_ICON_COLOUR : 'grey'};
    stroke: ${props => props.active ? DEFAULT_ICON_COLOUR : 'grey'};;
  }
`

const ImageStatusContainer = styled.div`
  position: relative;
`

const RecordStatus = styled.div`
  position: absolute;
  right: -5px;
  top: -10px;
  font-size: 2em;
  z-index: 4;
  color: green;
`

type BulkAutoUpdateBreadcrumbType = {
  bulkAutoUpdateFlowState: BulkUpdateFlowStateType,
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
} & BulkUpdateContextType

function BulkAutoUpdateBreadcrumb (
  {
    bulkAutoUpdateFlowState,
    setBulkAutoUpdateFlowState,
    site,
    services,
  }: BulkAutoUpdateBreadcrumbType) {
  const [progress, setProgress] = useState({
    site: false,
    service: false,
    review: false,
  })

  useEffect(() => {
    if (bulkAutoUpdateFlowState === 'site') {
      setProgress({
        site: true,
        service: false,
        review: false,
      })
    } else if (bulkAutoUpdateFlowState === 'service') {
      setProgress({
        site: true,
        service: true,
        review: false,
      })
    } else if (bulkAutoUpdateFlowState === 'review') {
      setProgress({
        site: true,
        service: true,
        review: true,
      })
    } else {
      setProgress({
        site: false,
        service: false,
        review: false,
      })
    }
  }, [bulkAutoUpdateFlowState])

  const allServicesConfirmed = () => {
    return services
      ? services.every(service => service.confirmed)
      : false
  }

  return <BulkAutoUpdateBreadcrumbContainer>
    <Row align='center'>
      <Progress>
        <ProgressBar active={progress.site}/>
      </Progress>
      <Col style={{ padding: 0 }} align='center'>
        <ImageStatusContainer>
          {site && site.confirmed && <RecordStatus >
            <FontAwesomeIcon icon={faCheckCircle as IconProp} />
          </RecordStatus> }
          <BulkUpdateStageButton
            active={progress.site}
            name='site'
            currentState={bulkAutoUpdateFlowState}
            onClick={() => {
              setBulkAutoUpdateFlowState('site')
            }}
          >
            <SiteImg />
            Update site
          </BulkUpdateStageButton>
        </ImageStatusContainer>
      </Col>
      <Progress>
        <ProgressBar active={progress.service}/>
      </Progress>
      <Col style={{ padding: 0 }} align='center'>
        <ImageStatusContainer>
          {allServicesConfirmed() && <RecordStatus >
            <FontAwesomeIcon icon={faCheckCircle as IconProp} />
          </RecordStatus> }
          <BulkUpdateStageButton
            active={progress.service}
            name='service'
            currentState={bulkAutoUpdateFlowState}
            onClick={() => setBulkAutoUpdateFlowState('service')}
          >
            <ServiceImg />
            Update service
          </BulkUpdateStageButton>
        </ImageStatusContainer>
      </Col>
      <Progress>
        <ProgressBar active={progress.review}/>
      </Progress>
      <Col style={{ padding: 0 }} align='center'>
        <BulkUpdateStageButton
          active={progress.review}
          name='review'
          currentState={bulkAutoUpdateFlowState}
          onClick={() => {
            setBulkAutoUpdateFlowState('review')
          }}
        >
          <ReviewImg />
          Review & submit
        </BulkUpdateStageButton>
      </Col>
      <Progress>
        <ProgressBar active={progress.review}/>
      </Progress>
    </Row>
  </BulkAutoUpdateBreadcrumbContainer>
}

export default BulkAutoUpdateBreadcrumb
