import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from '@ix/ix-ui'
import { AgeGroupType, BulkServiceProviderUpdateType } from '../../services/spud.service'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faInfo, faUndo } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  bulkUpdateRecordChangeReviewType,
  flattenLocationObj,
  flattenOpeningHoursObj,
} from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'
import { OpenHoursType } from '../General/OpeningHours/SPUDOpeningHours'
import { format } from 'date-fns'
import { OutsideComponentClick } from '../../effects/OutsideComponentClick'
import BulkUpdateRecordChangeContentPopup from '../BulkUpdateRecordChangeContentPopup'
import {
  indicateChanges,
  renderArrayDifferences,
  sortObject,
} from '../../externalPages/BulkAutoUpdate/BulkUpdateChanges.service'
import { diffArrays, diffWords } from 'diff'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { ReferenceType, VirtualElement } from '@floating-ui/react'
import * as _ from 'lodash'
import { fixAgeGroups } from '../../helpers/record'

const BulkUpdateRecordChangeReviewContainer = styled.div`
  padding: 0.5em;
  margin: 5px 0;
  background-color: #f3f3f3;
  border-radius: 3px;
`
const BulkUpdateRecordChangeReviewRow = styled(Row)`
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #478FE5;
  background-color: #E3EDFA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BulkUpdateContactInfoButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: 1px solid black;
  height: 2em;
  width: 2em;
  border-radius: 50%;
`

const BulkUpdateRecordChangeReviewButton = styled(Button)`
  background: none;
  font-size: 1em;
  color: ${props => props.theme.colors.primary};
  :hover {
    background: none;
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
  }
`

type BulkUpdateRecordChangeReviewProps = {
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  fieldKey: string,
  fieldLabel: string,
  setValue?: (key: string, value: unknown, newChanges?: boolean) => void
  currentFieldValue: bulkUpdateRecordChangeReviewType
  currentOpeningHours?: Array<OpenHoursType>,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
  allowChange?: boolean
  ageGroups?: {
      data: {
        results: Array<AgeGroupType>
        next: string | null
      }
    }
}

function BulkUpdateRecordChangeReview (
  {
    bulkUpdateChanges,
    fieldKey,
    fieldLabel,
    setValue,
    currentFieldValue,
    currentOpeningHours,
    setResetBulkUpdateRecordChanges,
    ageGroups,
    resetBulkUpdateRecordChanges = false,
    allowChange = true,
  }: BulkUpdateRecordChangeReviewProps) {
  const [currentValue, setCurrentValue] = useState<bulkUpdateRecordChangeReviewType>()
  const [valueChanged, setValueChanged] = useState<{[x: string]: boolean}>({})
  const [bulkUpdateContactPopup, setBulkUpdateContactPopup] = useState<{[x: string]: boolean}>({})
  const [contextRefs, setContextRefs] = useState<{
    [x: string]: {
      refs: {
        setReference:((node: ReferenceType | null) => void) & ((node: Element | VirtualElement | null) => void)
          },
      refPropsFunc:() => {[x:string]: unknown}}
          }>({})
  const [currentRow, setCurrentRow] = useState<string>('')
  const [originalText, setOriginalText] = useState<string>('')
  const [originalArray, setOriginalArray] = useState<Array<string>>([])
  const BulkUpdateRecordChangeReviewRef = useRef(null)
  const clickedOutsideComponent = OutsideComponentClick(BulkUpdateRecordChangeReviewRef)

  const closeAllPopups = () => {
    const closePopups: {[x: string]: boolean} = {}
    Object.keys(bulkUpdateContactPopup).forEach((key) => {
      closePopups[key] = false
    })
    setBulkUpdateContactPopup(closePopups)
  }

  useEffect(() => {
    if (clickedOutsideComponent) {
      closeAllPopups()
    }
  }, [clickedOutsideComponent])

  useEffect(() => {
    if (resetBulkUpdateRecordChanges) {
      setCurrentValue(undefined)
      setValueChanged({})
      setResetBulkUpdateRecordChanges?.(false)
      typeof currentFieldValue === 'string' && setOriginalText(currentFieldValue as string)
    }
  }, [resetBulkUpdateRecordChanges])

  useEffect(() => {
    if (fieldKey === 'opening_hours_simple' && currentOpeningHours) {
      setCurrentValue(flattenOpeningHoursObj(currentOpeningHours) as bulkUpdateRecordChangeReviewType)
    } else if (fieldKey === 'location' && currentFieldValue?.state) {
      !currentValue?.state && setCurrentValue(currentFieldValue)
    } else if (Array.isArray(currentFieldValue)) {
      !currentValue?.length && setCurrentValue(currentFieldValue)
    } else {
      !currentValue && setCurrentValue(currentFieldValue)
    }

    let defaultVals = {}
    bulkUpdateChanges?.update_data?.forEach((test, index) => {
      if (test?.data[fieldKey]) {
        defaultVals = {
          ...defaultVals,
          [`${fieldKey}_${index}`]: true,
        }
      }
    })
  }, [currentFieldValue])

  useEffect(() => {
    typeof currentFieldValue === 'string' && setOriginalText(currentFieldValue)
  }, [])

  useEffect(() => {
    if (Array.isArray(currentFieldValue)) {
      setOriginalArray(currentFieldValue as string[])
    }
  }, [])

  const hasDifference = (value: unknown) => {
    let hasDifferenceBool
    if (Array.isArray(value)) {
      hasDifferenceBool = !_.isEqual(originalArray, value)
    } else {
      try {
        hasDifferenceBool = diffWords(currentFieldValue || '', value as string).find(diff => {
          if (diff.value === originalText) {
            return false
          }
          return true
        })
      } catch (error) {
        console.error('Error in diffWords', error)
        return false
      }
    }
    return hasDifferenceBool
  }

  const ChangeValue = ({ newValue, field }: {newValue: unknown, field: string}) => {
    if (typeof newValue === 'object') {
      if (Array.isArray(newValue) && Array.isArray(currentValue)) {
        const originalArrayValues = originalArray.map(obj => {
          if (typeof obj === 'string') {
            return obj
          }
          let arrayObj = obj as bulkUpdateRecordChangeReviewType
          arrayObj = sortObject(
            arrayObj as bulkUpdateRecordChangeReviewType,
            field as keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location,
          ) as bulkUpdateRecordChangeReviewType
          if (Object.hasOwn(arrayObj, 'confidential')) {
            arrayObj.confidential = arrayObj.confidential ? '(is confidential)' : ''
          }
          return Object.values(arrayObj).join(' ').trim()
        })
        const newArrayValues = newValue.map(obj => {
          if (typeof obj === 'string') {
            return obj
          }
          obj = sortObject(
            obj,
            field as keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location,
          )
          if (Object.hasOwn(obj, 'confidential')) {
            obj.confidential = obj.confidential ? '(is confidential)' : ''
          }
          return Object.values(obj).join(' ').trim()
        })
        return <div>
          {renderArrayDifferences(diffArrays(originalArrayValues, newArrayValues)).map((word, index) => (
            <React.Fragment key={index}>{word} </React.Fragment>
          ))}
        </div>
      } else if (field === 'location' && currentValue) {
        const oldLocation = flattenLocationObj(currentValue as {
          building_name: string,
          confidential: boolean,
          flat_unit: string,
          floor_level: string,
          postcode: string,
          state: string,
          street_number: string,
          street_name: string,
          street_suffix: string,
          street_type: string,
          suburb: string,
          geo_point: {
            lat: string,
            lon: string
          },
        })
        const newLocation = flattenLocationObj(newValue as {
          building_name: string,
          confidential: boolean,
          flat_unit: string,
          floor_level: string,
          postcode: string,
          state: string,
          street_number: string,
          street_name: string,
          street_suffix: string,
          street_type: string,
          suburb: string,
          geo_point: {
            lat: string,
            lon: string
          },
        })
        return <div> {indicateChanges(oldLocation, newLocation).map((word, index) => (
          <React.Fragment key={index}>{word} </React.Fragment>
        ))}
        </div>
      }
    }

    if (
      newValue !== null && typeof newValue === 'string' && (
        currentValue !== undefined || currentFieldValue !== undefined
      )) {
      return <div>
        {indicateChanges(currentFieldValue, newValue, false).map((word, index) => (
          <React.Fragment key={index}>{word} </React.Fragment>
        ))}
      </div>
    }
    return null
  }

  const formatDateSubmitted = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy hh:mm aa')
  }

  const UpdateContainsField = () => {
    const containsField = bulkUpdateChanges.update_data.find(update => Object.hasOwn(update.data, fieldKey))
    if (containsField && Array.isArray(containsField?.data?.[fieldKey])) {
      const containsFieldArr = containsField.data[fieldKey] as Array<unknown>
      return containsField.data?.[fieldKey] && containsFieldArr.length > 0
    }
    if (typeof containsField?.data?.[fieldKey] === 'string') {
      return containsField?.data?.[fieldKey] !== ''
    }
    return containsField?.data?.[fieldKey]
  }

  const closeOtherPopups = (key: string) => {
    if (currentRow && key !== currentRow && bulkUpdateContactPopup[currentRow]) {
      closeAllPopups()
    }
    setCurrentRow(key)
  }

  if (bulkUpdateChanges?.update_data?.length) {
    return UpdateContainsField()
      ? <BulkUpdateRecordChangeReviewContainer ref={BulkUpdateRecordChangeReviewRef}>
        <>
          {bulkUpdateChanges?.update_data?.map((externalUpdate, index) =>
            (externalUpdate.data?.[fieldKey] || externalUpdate.data?.[fieldKey] === '') &&
            <BulkUpdateRecordChangeReviewRow
              align='center'
              key={`${fieldKey}_${index}`}
              ref={contextRefs[`${fieldKey}_${index}_contact`]?.refs?.setReference}
              {...contextRefs[`${fieldKey}_${index}_contact`]?.refPropsFunc()}
            >
              <Col direction='row' justify='flex-start'>
                <Row>
                  <Col>
                    <Row>
                      <strong style={{ textTransform: 'capitalize' }}>{fieldLabel} (Update {index + 1}):</strong>
                      <div style={{ margin: '0 0.5em' }}>
                        <>
                          <ChangeValue
                            newValue={externalUpdate.data?.[fieldKey]}
                            field={fieldKey}
                          />
                        </>
                      </div>
                    </Row>
                    {externalUpdate.author.contact_name && <Row>
                      Updated by - {externalUpdate.author.contact_name} | {
                        formatDateSubmitted(externalUpdate.date_submitted)
                      }
                      <div
                        style={{ marginLeft: '1em' }}
                        aria-label={`${fieldKey}_${index}_contact`}
                      >
                        <BulkUpdateContactInfoButton
                          onClick={() => {
                            setBulkUpdateContactPopup({
                              ...bulkUpdateContactPopup,
                              [`${fieldKey}_${index}_contact`]: true,
                            })
                          }}
                        >
                          <FontAwesomeIcon icon={faInfo as IconProp} />
                        </BulkUpdateContactInfoButton>
                        <BulkUpdateRecordChangeContentPopup
                          author={externalUpdate.author}
                          isOpen={bulkUpdateContactPopup?.[`${fieldKey}_${index}_contact`]}
                          setIsOpen={() => {
                            if (bulkUpdateContactPopup?.[`${fieldKey}_${index}_contact`]) {
                              setBulkUpdateContactPopup({
                                ...bulkUpdateContactPopup,
                                [`${fieldKey}_${index}_contact`]: false,
                              })
                            } else {
                              closeOtherPopups(`${fieldKey}_${index}_contact`)
                            }
                          }}
                          parentRefs={(refs, refPropsFun) => {
                            if (!Object.hasOwn(contextRefs, `${fieldKey}_${index}_contact`)) {
                              setContextRefs({
                                ...contextRefs,
                                [`${fieldKey}_${index}_contact`]: {
                                  refs: {
                                    setReference: refs.setReference,
                                  },
                                  refPropsFunc: refPropsFun,
                                },
                              })
                            }
                          }}
                        />
                      </div>
                    </Row>}
                  </Col>
                </Row>
              </Col>
              {allowChange &&
                hasDifference(externalUpdate.data?.[fieldKey]) &&
                <Col direction='row' justify='flex-end'>
                  {!valueChanged[`${fieldKey}_${index}`] &&
                    <BulkUpdateRecordChangeReviewButton
                      onClick={() => {
                        if (fieldKey === 'age_groups' && ageGroups) {
                          setValue?.(fieldKey, fixAgeGroups(
                            externalUpdate.data?.[fieldKey] as Array<string>,
                            ageGroups,
                          ), true)
                        } else {
                          setValue?.(fieldKey, externalUpdate.data?.[fieldKey], true)
                        }
                        setValueChanged({
                          ...valueChanged,
                          [`${fieldKey}_${index}`]: true,
                        })
                      }}
                      title='accept changes'
                    >
                      <FontAwesomeIcon icon={faCheck as IconProp} />
                    </BulkUpdateRecordChangeReviewButton>}
                  {valueChanged[`${fieldKey}_${index}`] &&
                    <BulkUpdateRecordChangeReviewButton
                      onClick={() => {
                        // Special case for when the initial field is empty and
                        // you are adding a new value as part of the bulk update,
                        // but you want to revert back to the original value
                        if (Array.isArray(currentFieldValue)) {
                          if (fieldKey === 'age_groups' && ageGroups) {
                            setValue?.(fieldKey, fixAgeGroups(
                              (
                                !_.isEqual(originalArray, currentValue)
                                  ? originalArray
                                  : currentValue
                              ) as string[],
                              ageGroups,
                            ))
                            setCurrentValue(fixAgeGroups(
                              (
                                !_.isEqual(originalArray, currentValue)
                                  ? originalArray
                                  : currentValue
                              ) as string[],
                              ageGroups,
                            ) as bulkUpdateRecordChangeReviewType)
                          } else {
                            setValue?.(
                              fieldKey,
                              !_.isEqual(originalArray, currentValue) ? originalArray : currentValue,
                            )
                            setCurrentValue(
                              (
                                !_.isEqual(originalArray, currentValue)
                                  ? originalArray
                                  : currentValue
                              ) as bulkUpdateRecordChangeReviewType,
                            )
                          }
                        } else {
                          setValue?.(fieldKey, originalText !== currentValue ? originalText : currentValue)
                          setCurrentValue(
                            (
                              originalText !== currentValue
                                ? originalText
                                : currentValue
                            ) as bulkUpdateRecordChangeReviewType,
                          )
                        }
                        setValueChanged({
                          ...valueChanged,
                          [`${fieldKey}_${index}`]: false,
                        })
                      }}
                      title='undo changes'
                    >
                      <FontAwesomeIcon icon={faUndo as IconProp} />
                    </BulkUpdateRecordChangeReviewButton>}
                </Col>}
              {allowChange && !hasDifference(externalUpdate.data?.[fieldKey]) &&
                <Col direction='row' justify='flex-end'>
                  No Difference with current value
                </Col>
              }
            </BulkUpdateRecordChangeReviewRow>,
          )}
        </>
      </BulkUpdateRecordChangeReviewContainer>
      : null
  }
  return null
}

export default BulkUpdateRecordChangeReview
