import { Col, Row, Title, Alert } from '@ix/ix-ui'
import {
  convert24hrTo12HrFormat,
  DAYS_OF_THE_WEEK, OpeningHoursSummaryChip, OpeningHoursSummaryChipDay,
  OpeningHoursSummaryChipTime,
  OpeningHoursSummaryContent,
  renderNote,
} from './OpeningHours.service'
import _ from 'lodash'
import React from 'react'
import { OpenHoursType } from './SPUDOpeningHours'

type SiteOpeningHoursProps = {
  siteOpeningHours: Array<OpenHoursType>,
  bulkUpdateForm: boolean,
  emptyServiceTimes?: boolean,
}

function SiteOpeningHours ({ siteOpeningHours, bulkUpdateForm, emptyServiceTimes }: SiteOpeningHoursProps) {
  return <Row>
    <Row>
      <Col><Title level={4}>➥ Site Opening Hours Summary</Title></Col>
    </Row>
    {bulkUpdateForm && <Row>
      {emptyServiceTimes
        ? <Col>
          <Alert type='info'>
              If the opening hours for this service differ from what’s below please enter the times above.
              If you have updated the site’s opening hours and don’t want any changes for this service, leave as is.
          </Alert>
        </Col>
        : <Col>
          <Alert type='info'>
            The service opening hours are listed above. Please change if needed.
          </Alert>
        </Col>
      }
    </Row>}
    <Row>
      <Col>
        <div>
          {DAYS_OF_THE_WEEK.map((dayOfTheWeek, index) =>
            _.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name]?.length &&
              <OpeningHoursSummaryContent key={`site_${dayOfTheWeek.name}_${index}`}>
                <OpeningHoursSummaryChip clicked={false} readonly={true}>
                  <div style={{ display: 'flex' }}>
                    <OpeningHoursSummaryChipDay>
                      {_.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name].length && dayOfTheWeek.name} |
                    </OpeningHoursSummaryChipDay>
                    {_.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name].map((siteTime, index) =>
                      <OpeningHoursSummaryChipTime key={`siteTime_${dayOfTheWeek.name}_${index}`}>
                        {convert24hrTo12HrFormat(siteTime.open)} - {convert24hrTo12HrFormat(siteTime.close)}
                      </OpeningHoursSummaryChipTime>,
                    )}
                    {renderNote(dayOfTheWeek.name, true, siteOpeningHours)}
                  </div>
                </OpeningHoursSummaryChip>
              </OpeningHoursSummaryContent>,
          )}
        </div>
      </Col>
    </Row>
  </Row>
}

export default SiteOpeningHours
