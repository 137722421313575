import keycloak from '../../keycloak'
import NotificationApi, { getSPUDBulkUpdateEnvironment } from '../helpers/NotificationAPI'

export const loadTemplates = async () => {
  const searchURL = `v1/template/?environment__name=${getSPUDBulkUpdateEnvironment()}`

  const response = await NotificationApi.get(searchURL, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  })
  return response.data
}
