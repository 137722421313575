import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Col, Title } from '@ix/ix-ui'
import {
  AdvancedFilterType, allocatedUserFilterSwitches, fetchQuickFilterPresetByRole,
  getFilterFields, getFilterIndex, QuickFilterPresetType,
} from './RecordFilter.service'
import { Option } from '../../components/forms/AddEditRecord.type'
import AdvancedFilterSummaryCard from './AdvancedFilterSummaryCard'
import AdvancedFilterAddEditCard from './AdvancedFilterAddEditCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import QuickFilterPresets from './QuickFilterPresets'

type AdvancedFiltersProps = {
  onDismissAdvancedFilters: () => void
  recordType: string,
  onUpdateFilters: (filters: {[x: string]: AdvancedFilterType}) => void,
  currentPageFilters?: {[x: string]: AdvancedFilterType},
  userRole: 'Administrator'|'Updater'|'Editor',
  pageType: 'dashboard'|'record-list'|'email-campaign',
  editingFilter: string,
}

const AdvancedFilterContainer = styled.div<{calculatedHeight: number}>`
  width: 100%;
  height: ${props => `${props.calculatedHeight}px` || '100vh'};
  background-color: #E6E6E6;
  padding: 1em;
`

const AdvancedFilterAddNewCardContainer = styled.div`
  padding: 1em;
  margin: 1em;
  border: 1px solid grey;
`

export const AdvancedFilterLinkButton = styled(Button)`
  border: none;
  background: none;
  margin: 0;
  :hover {
    background: none;
    text-decoration: underline;
  }
  :focus {
    box-shadow: none;
  }
`

const AdvancedFilterHr = styled.hr`
  width: 100%;
  height: 2px;
`

function AdvancedFilters (
  {
    onDismissAdvancedFilters,
    recordType,
    onUpdateFilters,
    currentPageFilters,
    userRole,
    pageType,
    editingFilter,
  }: AdvancedFiltersProps) {
  const [filterFields, setFilterFields] = useState<Array<AdvancedFilterType>>([])
  const [filterValues, setFilterValues] = useState<{[x: string]: AdvancedFilterType}>({})
  const [filterFieldOptions, setFilterFieldOptions] = useState<Array<Option>>([])
  const [addFilter, setAddFilter] = useState(false)
  const [quickPresets, setQuickPresets] = useState<Array<QuickFilterPresetType>>([])

  useEffect(() => {
    const presets = fetchQuickFilterPresetByRole(userRole, pageType, recordType)
    setQuickPresets(presets.filter(preset => preset.position === 'advanced-panel'))
  }, [userRole])

  useEffect(() => {
    let filterFields = getFilterFields(recordType)
    filterFields = filterFields.filter(filter => {
      if (userRole === 'Administrator' && filter.role === 'Administrator') {
        return true
      } else if (userRole !== 'Administrator' && filter.role === 'Administrator') {
        return false
      }
      return true
    })
    setFilterFieldOptions(
      filterFields.map(field => (
        {
          id: field.name,
          name: field.label,
        }),
      ))
    setFilterFields(filterFields)
  }, [recordType])

  useEffect(() => {
    if (currentPageFilters) {
      setFilterValues(currentPageFilters)
    }
  }, [currentPageFilters])

  const onAddNewFilter = (activeFilter: AdvancedFilterType, filterKey?: string) => {
    if (activeFilter?.name) {
      if (activeFilter?.value === undefined) {
        activeFilter = { ...activeFilter, value: activeFilter.defaultValue }
      }
      let filterFieldKey
      if (filterKey) {
        filterFieldKey = filterKey
      } else {
        const nextIndex = getFilterIndex(filterValues, activeFilter, false)
        filterFieldKey = `${activeFilter?.name}-${nextIndex[activeFilter.name]}`
      }
      let newFilters = {
        ...filterValues,
        [filterFieldKey]: { ...activeFilter },
      }
      newFilters = allocatedUserFilterSwitches(newFilters, activeFilter)
      setFilterValues(newFilters)
      onUpdateFilters(newFilters)
    }
  }

  const onDeleteFilter = (activeFilter: AdvancedFilterType, filterKey?: string) => {
    if (activeFilter?.name) {
      const nextIndex = getFilterIndex(filterValues, activeFilter, true)
      const tempFilterValues = { ...filterValues }
      if (filterKey) {
        delete tempFilterValues[filterKey]
      } else {
        delete tempFilterValues[`${activeFilter.name}-${nextIndex[activeFilter.name]}`]
      }
      setFilterValues(tempFilterValues)
      onUpdateFilters(tempFilterValues)
    }
  }

  return <AdvancedFilterContainer calculatedHeight={document.documentElement.clientHeight}>
    <Row align='center'>
      <Col>
        <Title level={2} marginTop='0'>
          Filters
        </Title>
      </Col>
      <Col direction='row' justify='flex-end'>
        <AdvancedFilterLinkButton
          onClick={() => onDismissAdvancedFilters()}
        >
          <FontAwesomeIcon icon={faTimes as IconProp} size='2x'/>
        </AdvancedFilterLinkButton>
      </Col>
    </Row>
    <Row align='center'>
      <Col>
        <Button onClick={() => setAddFilter(!addFilter)} primary>
          <FontAwesomeIcon icon={faPlus as IconProp}/> {addFilter ? 'Cancel add new' : 'Add'}
        </Button>
      </Col>
      <Col direction='row' justify='flex-end'>
        <AdvancedFilterLinkButton
          onClick={() => {
            if (Object.keys(filterValues).length > 0) {
              setFilterValues({})
              onUpdateFilters({})
            }
          }}
        >
          Clear all
        </AdvancedFilterLinkButton>
      </Col>
    </Row>
    <Row>
      <Col>
        <QuickFilterPresets
          presets={quickPresets}
          activeFilters={filterValues}
          onPresetChange={(preset) => {
            if (preset.value) {
              onAddNewFilter(preset, `${preset.name}-0`)
            } else {
              onDeleteFilter(preset)
            }
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <AdvancedFilterHr />
      </Col>
    </Row>
    {addFilter &&
      <AdvancedFilterAddNewCardContainer>
        <AdvancedFilterAddEditCard
          filterFields={filterFields}
          dismissAddEditCard={() => setAddFilter(false)}
          recordType={recordType}
          filterFieldOptions={filterFieldOptions}
          onAddNewFilter={onAddNewFilter}
        />
      </AdvancedFilterAddNewCardContainer>
    }
    <Row>
      <Col>
        {Object.entries(filterValues).map(([key, value]) =>
          <div key={key}>
            <AdvancedFilterSummaryCard
              filterField={value}
              onAddNewFilter={onAddNewFilter}
              filterFields={filterFields}
              recordType={recordType}
              filterKey={key}
              onDeleteFilter={onDeleteFilter}
              editingFilter={editingFilter}
            />
          </div>,
        )}
      </Col>
    </Row>
  </AdvancedFilterContainer>
}

export default AdvancedFilters
