import React, { useEffect, useState } from 'react'
import { Title, Row, Col } from '@ix/ix-ui'
import styled from 'styled-components'
import { SPUDRecord } from '../../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import BulkAutoUpdateForm from './BulkAutoUpdateForm'
import { BulkAutoUpdateRecord, BulkUpdateContextType, BulkUpdateSPUDRecordType } from './BulkUpdateContext.type'
import { BulkUpdateFlowStateType } from './BulkAutoUpdateService'
import { SPUDInputField } from '../../helpers/record'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'

const SPUDSiteForm = styled.div`
  width: 70%;
`

const FormContainer = styled.div`
  width: 100%;
  margin-left: 0;
  padding: 1em;
  background-color: #fff;
`

type SiteFormProps = {
  recordId: number,
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
} & BulkUpdateContextType

function SiteForm (
  {
    recordId,
    organisation,
    saveSiteData,
    setBulkAutoUpdateFlowState,
    site,
    activeServiceIndexContext,
    saveOrganisationData,
    setDialogOptions,
  }: SiteFormProps) {
  const [siteData, setSiteData] = useState<BulkAutoUpdateRecord<SPUDSiteRecordDetails>>()

  const updateOrganisation = (key: keyof SPUDOrganisationRecordDetails, value: unknown) => {
    let currentData = organisation.currentData
    currentData = {
      ...currentData,
      update: {
        ...organisation.currentData.update,
        data: {
          ...organisation.currentData.update.data,
          [key]: value,
        },
      },
    } as SPUDRecord<SPUDOrganisationRecordDetails>
    saveOrganisationData({
      recordType: 'organisation',
      currentData: currentData,
      originalData: organisation.originalData,
      comment: '',
      confirmed: true,
      deleted: false,
      noChanges: false,
    })
  }

  const updateSiteName = (key: keyof SPUDSiteRecordDetails, value: string) => {
    if (siteData) {
      let currentData = { ...siteData.currentData }
      currentData = {
        ...currentData,
        update: {
          ...siteData.currentData.update,
          data: {
            ...siteData.currentData.update.data,
            [key]: value,
          },
        },
      } as SPUDRecord<SPUDSiteRecordDetails>
      const updatedSiteData = {
        recordType: 'site',
        currentData: currentData,
        originalData: siteData.originalData,
        comment: siteData?.comment,
        confirmed: siteData.confirmed,
        noChanges: !siteData?.noChanges,
        deleted: false,
      }
      setSiteData(updatedSiteData)
      saveSiteData(updatedSiteData)
    }
  }

  useEffect(() => {
    setSiteData(site)
  }, [])

  const resetOrganisation = () => {
    saveOrganisationData({
      recordType: 'organisation',
      currentData: organisation.originalData,
      originalData: organisation.originalData,
      comment: '',
      confirmed: true,
      deleted: false,
      noChanges: false,
    })
  }

  const resetSiteName = () => {
    if (siteData) {
      const updatedSiteData = {
        recordType: 'site',
        currentData: siteData?.originalData as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>,
        originalData: siteData?.originalData as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>,
        comment: siteData?.comment,
        confirmed: true,
        noChanges: false,
        deleted: false,
      }
      setSiteData(updatedSiteData)
      saveSiteData(updatedSiteData)
    }
  }

  return <SPUDSiteForm>
    <Row style={{ padding: '1em' }}>
      <Col>
        <Title>
          Update site {siteData?.originalData.update?.data?.name
            ? ` - ${siteData?.originalData.update?.data?.name}`
            : ''
          }
        </Title>
      </Col>
    </Row>
    <Row style={{ padding: '1em' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    <Row>
      <Col style={{ padding: 10 }}>
        <SPUDInputField
          label='Site name'
          name='name'
          value={siteData?.currentData.update?.data?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateSiteName('name', event.target.value)
          }}
          fullwidth
        />
      </Col>
    </Row>
    {organisation && <Row>
      <Col style={{ padding: 10 }}>
        <SPUDInputField
          label='Organisation name'
          value={organisation.currentData.update.data?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateOrganisation('name', event.target.value)
          }}
          fullwidth
        />
      </Col>
    </Row>}
    {organisation && <Row>
      <Col style={{ padding: 10 }}>
        <SPUDInputField
          label='Organisation ABN'
          value={organisation.currentData.update.data.abn}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateOrganisation('abn', event.target.value)
          }}
          fullwidth
        />
      </Col>
    </Row>}
    <Row>
      <Col>
        {siteData && <FormContainer>
          <BulkAutoUpdateForm
            recordId={recordId.toString()}
            recordType='site'
            formData={siteData.currentData.update?.data as unknown as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>}
            originalData={siteData.originalData.update?.data}
            contextUpdater={(
              data,
              comment,
              confirm,
              noChanges,
              deleted,
            ) => {
              let currentData = siteData.currentData
              currentData = {
                ...currentData,
                update: {
                  ...siteData.currentData.update,
                  data: {
                    ...siteData.currentData.update.data,
                    ...data,
                  },
                },
              } as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>
              saveSiteData({
                recordType: 'site',
                currentData: currentData,
                originalData: siteData.originalData,
                comment: comment,
                confirmed: confirm,
                deleted: deleted,
                noChanges: noChanges,
              })
            }}
            onNext={() => {
              setBulkAutoUpdateFlowState('service')
            }}
            confirmed={site.confirmed}
            noChanges={site.noChanges}
            comment={site.comment}
            willBeDeleted={site.deleted}
            activeServiceIndexContext={activeServiceIndexContext}
            setDialogOptions={setDialogOptions}
            isNewService={false}
            organisationData={organisation}
            resetOrganisation={resetOrganisation}
            resetSiteName={resetSiteName}
          />
        </FormContainer>}
      </Col>
    </Row>
  </SPUDSiteForm>
}

export default SiteForm
