import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Title } from '@ix/ix-ui'
import SPUDAutoComplete from '../../components/General/SPUDAutoComplete'
import { Option } from '../../components/forms/AddEditRecord.type'
import { AdvancedFilterType, fetchFilterField, FilterValueType, SearchOperators } from './RecordFilter.service'
import styled, { useTheme } from 'styled-components'
import { useAuth } from '../../helpers/auth'

type AdvancedFilterAddEditCardProps = {
  filterFields: Array<AdvancedFilterType>,
  filterFieldOptions?: Array<Option>,
  onAddNewFilter: (filter: AdvancedFilterType, filterKey?: string) => void,
  recordType: string,
  dismissAddEditCard: () => void,
  editingActiveFilter?: AdvancedFilterType,
  filterKey?: string
}

const EditFilterHr = styled.hr`
  width: 100%;
  height: 2px;
`

function AdvancedFilterAddEditCard (
  {
    filterFields,
    filterFieldOptions,
    onAddNewFilter,
    recordType,
    dismissAddEditCard,
    editingActiveFilter,
    filterKey,
  }: AdvancedFilterAddEditCardProps) {
  const [activeFilterOption, setActiveFilterOption] = useState<Option | null>(null)
  const [activeFilter, setActiveFilter] = useState<AdvancedFilterType | null>(null)

  const { userRole } = useAuth()

  // fetch the colors from the default theme
  const { colors } = useTheme() as {colors: {[x: string]: string}}

  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      backgroundColor: `${colors.grayEvenLighter}`,
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
      textAlign: 'left',
    }),
    menuList: () => ({
      textAlign: 'left',
    }),
  }

  useEffect(() => {
    if (editingActiveFilter) {
      setActiveFilter(editingActiveFilter)
    }
  }, [editingActiveFilter])

  const onFilterValueChange = (
    filterField: AdvancedFilterType,
    filterValue: FilterValueType,
    searchOperator?: SearchOperators,
  ) => {
    setActiveFilter({ ...filterField, value: filterValue, searchOperator })
  }

  const showFilterBasedOnRole = (activeFilter: AdvancedFilterType): boolean => {
    return activeFilter.role === 'any' || activeFilter.role === userRole
  }

  return (
    <div>
      <Row>
        <Col>
          <Title level={3}>
            {editingActiveFilter ? 'Update' : 'Add'} filter {editingActiveFilter && `(${editingActiveFilter.label})`}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditFilterHr/>
        </Col>
      </Row>
      {!editingActiveFilter && <Row>
        <Col>
          <SPUDAutoComplete
            label="Select filter"
            customStyleOverride={customStyle}
            selectedOption={activeFilterOption}
            onChange={(filterOption) => {
              setActiveFilterOption(filterOption as Option)
              const selectedFilter = filterFields.find((filter) => filter.name === filterOption)
              if (selectedFilter && !editingActiveFilter) {
                setActiveFilter(selectedFilter)
              }
            }}
            options={filterFieldOptions}
            isMulti={false}
            disabled={false}
            isClearable={false}
          />
        </Col>
      </Row>}
      {activeFilter && showFilterBasedOnRole(activeFilter) &&
        <div>
          <Row>
            <Col>
              {fetchFilterField(
                activeFilter,
                onFilterValueChange,
                recordType,
              )}
            </Col>
          </Row>
          <Row>
            <Col direction='row' justify='flex-end'>
              <Button
                onClick={() => {
                  dismissAddEditCard()
                  setActiveFilter(null)
                  setActiveFilterOption(null)
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (editingActiveFilter && filterKey) {
                    onAddNewFilter(activeFilter, filterKey)
                  } else {
                    onAddNewFilter(activeFilter)
                  }
                  dismissAddEditCard()
                  setActiveFilter(null)
                }}
                primary
                disabled={activeFilter?.validation && !activeFilter.validation(activeFilter.value)}
              >
                {editingActiveFilter ? 'Update' : 'Add'} filter
              </Button>
            </Col>
          </Row>
        </div>
      }
    </div>
  )
}

export default AdvancedFilterAddEditCard
