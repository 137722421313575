import React, { useEffect, useState } from 'react'
import {
  AvatarIconWithStatusContent, AvatarIconWithStatusWrapper,
  Col,
  Section,
  Title,
  Row,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { fetchRecordIcon } from '../../../helpers/record'
import SPUDRecordStatus from '../../../components/General/SPUDRecordStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { LinkedRecord } from '../../../../@types/LinkedRecord.type'
import NoLinkedRecords from './NoLinkedRecords'

type Props = {
  title: string,
  loading: boolean,
  addNewComponent: React.ReactElement | null,
  linkedRecords: Array<LinkedRecord>
  recordType:string,
}

const LinkedRecordCardContainer = styled.div`
  margin: 4px 0;
  border: 2px solid #478FE5;
  background-color: #E3EDFA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
`

const LinkedRecordCardIcon = styled.div`
  margin: 0 10px;
`

const LinkedRecordCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  margin-left: 2em;
`

const LinkedRecordCardID = styled.h4`
  font-size: 1.1em;
  margin: 0;
  font-weight: 500;
  color: #111;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    font-size: 0.9rem;
  }
`

const LinkedRecordListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LinkedRecordsListTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
`

const LinkedRecordsContainer = styled.div`
  overflow-y: scroll;
  height: 80vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 75vh;
  }
`

const LinkedRecordsTitle = styled(Title)`
  font-size: 1.4em;
    
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    font-size: 1em;
  }
`

function LinkedRecordsList ({ title, addNewComponent, linkedRecords, loading, recordType }: Props): React.ReactElement {
  const [records, setRecords] = useState<Array<LinkedRecord>>([])

  useEffect(() => {
    if (loading) {
      setRecords([])
    } else {
      setRecords(linkedRecords)
    }
  }, [linkedRecords, loading])

  return (
    <Section style={{ padding: '0' }}>
      <LinkedRecordListTitleContainer>
        <LinkedRecordsListTitleWithIcon>
          <LinkedRecordCardIcon>
            <AvatarIconWithStatusWrapper md backgroundColour='#fff' style={{ marginLeft: '-5px' }}>
              <AvatarIconWithStatusContent>
                {fetchRecordIcon('recordType', '2x')}
              </AvatarIconWithStatusContent>
            </AvatarIconWithStatusWrapper>
          </LinkedRecordCardIcon>
          <Title level={3} marginTop='0'>{title}</Title>
        </LinkedRecordsListTitleWithIcon>
        {addNewComponent}
      </LinkedRecordListTitleContainer>
      {loading && 'Loading...'}
      <LinkedRecordsContainer>
        {
          records.length > 0
            ? records.map((record: LinkedRecord, index: number) => (
              <LinkedRecordCardContainer key={`${record.name}_${index}`}>
                <Row align="center">
                  <Col xs={8} xl={8} xxl={8} padding="0px">
                    <LinkedRecordCardDetails>
                      <LinkedRecordsTitle level={4} marginTop='0'>
                        {record.name}
                      </LinkedRecordsTitle>
                      <LinkedRecordCardID>
                        <span>ID: {record.id}</span>
                      </LinkedRecordCardID>
                      {record.referral_enabled && <div>
                        Referral enabled <FontAwesomeIcon icon={faNetworkWired as IconProp} color='#4F4F4F'/>
                      </div>}
                    </LinkedRecordCardDetails>
                  </Col>
                  <Col xs={4} xl={4} xxl={4} padding="1.5em">
                    <SPUDRecordStatus
                      status={record.status || 'Not set'}
                      active={record.is_active}
                      issId={record.iss_id}
                      table
                    />
                  </Col>
                </Row>
              </LinkedRecordCardContainer>
            ))
            : !loading && <NoLinkedRecords linkedRecord={recordType}/>
        }
      </LinkedRecordsContainer>
    </Section>
  )
}

export default LinkedRecordsList
