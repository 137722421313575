import { parse, areIntervalsOverlapping } from 'date-fns'
import { OpenHoursType } from './SPUDOpeningHours'
import _ from 'lodash'
import styled from 'styled-components'
import { FieldArrayWithId } from 'react-hook-form'

export type DayOfWeek = {
  name: string,
  abbreviation: string,
}

export type openCloseType = 'open' | 'close'

export const DAYS_OF_THE_WEEK: Array<DayOfWeek> = [
  { name: 'Monday', abbreviation: 'M' },
  { name: 'Tuesday', abbreviation: 'T' },
  { name: 'Wednesday', abbreviation: 'W' },
  { name: 'Thursday', abbreviation: 'T' },
  { name: 'Friday', abbreviation: 'F' },
  { name: 'Saturday', abbreviation: 'S' },
  { name: 'Sunday', abbreviation: 'S' },
  { name: 'Public Holiday', abbreviation: 'PUB' },
]

export type TimeType = {
  hour: string,
  minute: string,
  amPm: string,
}

export type OpenCloseHours = {
  open: string | number;
  close: string | number;
}

export const OpeningHoursSummaryChip = styled.div<{ clicked: boolean, readonly?: boolean}>`
  border-radius: 3px;
  border: ${props => props.clicked ? '2px' : '1px'} ;
  color: #767676;
  font-size: 10px;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
  width: 100%;
  position: relative;
  padding: 5px;
  :hover {
    cursor: ${props => !props.readonly ? 'pointer' : 'inherit'};
  }
`

export const OpeningHoursSummaryChipTime = styled.span`
  font-size: 14px;
  margin-right: 10px;
`

export const OpeningHoursSummaryContent = styled.div`
  display: flex;
  align-items: center;
`

export const OpeningHoursSummaryChipDay = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin: 0 10px;
`

/**
 * Validate currently entered time to solve these potential issues:
 *  1. If the closed time is before the open time
 *  2. If the open time is after the close time
 * @param time
 * @param openCloseHours
 * @param type
 * @return boolean
 */

export const isCloseTimeAfterOpenTime = (
  time: string | undefined,
  openCloseHours: OpenCloseHours,
  type: openCloseType,
): boolean => {
  const { open, close } = openCloseHours

  if (!time) {
    return false
  }

  if (type === 'open' && time > close) {
    return false
  } else if (type === 'close' && time < open) {
    return false
  } else {
    return true
  }
}

/**
 * Check if the time that is going to be added, doesn't overlap
 * e.g if 9 am - 5 pm is already added for a particular day check that
 * the new time doesn't overlap or is within 9 - 5
 * @param time
 * @param day
 * @param currentOpeningHours:
 */
export const checkForOverlappingTimes = (
  time: { open?: string, close?: string },
  day: string,
  currentOpeningHours: Array<OpenHoursType>,
): boolean => {
  const openingHours = _.groupBy(currentOpeningHours, 'day')

  return openingHours?.[day]?.some(hour => {
    if (hour?.open && hour?.close && time?.open && time?.close) {
      if (hour.open === '00:00:00' &&
        hour.close === '00:00:00') {
        return true
      } else if (
        (time.open === '00:00:00' && time.close === '00:00:00')
      ) {
        return true
      } else {
        try {
          return areIntervalsOverlapping({
            start: parse(hour.open, 'HH:mm:ss', new Date()),
            end: parse(hour.close, 'HH:mm:ss', new Date()),
          }, {
            start: parse(time.open, 'HH:mm:ss', new Date()),
            end: parse(time.close, 'HH:mm:ss', new Date()),
          }, { inclusive: true })
        } catch (e) {
          return !isCloseTimeAfterOpenTime(time.close, hour, 'close')
        }
      }
    }
    return false
  })
}

/**
 * Convert to the saved 24hr time to 12hr to make it more readable
 */
export const convert24hrTo12HrFormat = (time: string): string => {
  const [hours, minutes] = time.split(':')
  let formattedHours = parseInt(hours, 10)
  const amPm = formattedHours >= 12 ? 'pm' : 'am'

  formattedHours = formattedHours % 12 || 12

  return `${formattedHours.toString().padStart(2, '0')}:${minutes} ${amPm}`
}

// Ignoring because even the shape of field is known the type can't be set
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupDays = (fields: FieldArrayWithId[]): any => {
  return _.groupBy(fields, 'day')
}

export const renderNote = (
  day: string,
  site?: boolean,
  siteOpeningHours?: Array<OpenHoursType>,
  fields?: FieldArrayWithId[],
) => {
  let hours
  if (site && siteOpeningHours) {
    hours = _.groupBy(siteOpeningHours, 'day')?.[day]
  } else if (fields) {
    hours = groupDays(fields)?.[day]
  }

  // ensure that the most recent note is displayed
  const dayNotes = hours.reduce(
    (previousValue: OpenHoursType, currentValue: OpenHoursType) =>
      previousValue?.note ? currentValue.note || previousValue.note : currentValue.note,
  )

  if (typeof dayNotes?.note === 'string') {
    return `${dayNotes.note && '|'} ${dayNotes.note}`
  }
  return dayNotes?.note ? `${dayNotes.note && '|'} ${dayNotes.note}` : `${dayNotes && '|'}  ${dayNotes}`
}
