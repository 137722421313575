import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatDatetimeToLocal } from '../../../helpers/datetime'
import SPUDRecordStatus from '../../../components/General/SPUDRecordStatus'
import React from 'react'
import { SPUDRecord } from '../../../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../../@types/Service.type'
import { fetchCorrectIdToDisplay } from '../../../helpers/record'
import {
  AdvancedFilterType, fetchQuickFilterPresetByRole, fetchRequiredFields,
  FilterValueType,
  getFilterFields,
  SearchOperators,
} from '../../RecordFilter/RecordFilter.service'
import { formatInTimeZone as tsFormat } from 'date-fns-tz'
import { Option } from '../../../components/forms/AddEditRecord.type'
import { faInbox } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconContainerWrapper from '../../../components/IconContainerWrapper'
import SpudAvatar from '../../../components/SPUDAvatar'

const normaliseSearchText = (text: string) => text.trim().replace(/\s+/g, ' ')

/* eslint-disable camelcase */
export type RecordPageType = {
  id: string | React.ReactElement | null,
  name: string,
  date_started?: string,
  last_updated_date: string | null,
  last_modified_date?: string | null,
  suburb?: string | null,
  state?: string | null,
  status: string | React.ReactElement,
  record_type: string,
  allocated_user?: React.ReactElement | null,
  site?: React.ReactElement
  organisation?: string
  is_active: boolean,
  record_id: number | string | null,
  iss_id?: number | string | null,
}
/* eslint-enable camelcase */

export const flattenSelectedRows = (
  selectedRows: {
    [x: string]: {record_id: string | number, name: string}
  }): Array<{record_id: string | number, name: string}> => {
  const keys = Object.keys(selectedRows)
  return keys.map((key:string) => selectedRows?.[key])
}

export const PopUpBackdrop = styled.div<{containerHeight?: string}>`
  background-color: rgb(0 0 0 / 25%);
  z-index: 4;
  height: auto;
  min-height: 100vh;
  width: 100%;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MULTI_FIELDS = [
  'phones',
  'emails',
  'postal_addresses',
  'social_media',
  'opening_hours',
]

export const fetchRecordColumns = (
  recordType: 'site'|'service'|'organisation',
  userRole: 'Updater'|'Editor'|'Administrator',
): Array<{
  name: string,
  key: string,
}> => {
  switch (recordType) {
  case 'service':
    if (userRole === 'Administrator') {
      return [
        {
          name: 'ID',
          key: 'id',
        },
        {
          name: 'Service name',
          key: 'name',
        },
        {
          name: 'Site name',
          key: 'site',
        },
        {
          name: 'Suburb',
          key: 'suburb',
        },
        {
          name: 'State',
          key: 'state',
        },
        {
          name: 'Date created',
          key: 'date_started',
        },
        {
          name: 'Last modified',
          key: 'last_modified_date',
        },
        {
          name: 'Last updated',
          key: 'last_updated_date',
        },
        {
          name: 'Status',
          key: 'status',
        },
        {
          name: 'Has bulk update',
          key: 'has_bulk_updates',
        },
      ]
    }
    return [
      {
        name: 'ID',
        key: 'id',
      },
      {
        name: 'Service name',
        key: 'name',
      },
      {
        name: 'Site name',
        key: 'site',
      },
      {
        name: 'Suburb',
        key: 'suburb',
      },
      {
        name: 'State',
        key: 'state',
      },
      {
        name: 'Last updated',
        key: 'last_updated_date',
      },
      {
        name: 'Status',
        key: 'status',
      },
      {
        name: 'Has bulk update',
        key: 'has_bulk_updates',
      },
    ]
  case 'organisation':
    if (userRole === 'Administrator') {
      return [
        {
          name: 'ID',
          key: 'id',
        },
        {
          name: 'Organisation name',
          key: 'name',
        },
        {
          name: 'Date created',
          key: 'date_started',
        },
        {
          name: 'Last modified',
          key: 'last_modified_date',
        },
        {
          name: 'Last updated',
          key: 'last_updated_date',
        },
        {
          name: 'Status',
          key: 'status',
        },
        {
          name: 'Has bulk update',
          key: 'has_bulk_updates',
        },
      ]
    }
    return [
      {
        name: 'ID',
        key: 'id',
      },
      {
        name: 'Organisation name',
        key: 'name',
      },
      {
        name: 'Last updated',
        key: 'last_updated_date',
      },
      {
        name: 'Status',
        key: 'status',
      },
      {
        name: 'Has bulk update',
        key: 'has_bulk_updates',
      },
    ]
  default:
    if (userRole === 'Administrator') {
      return [
        {
          name: 'ID',
          key: 'id',
        },
        {
          name: 'Site name',
          key: 'name',
        },
        {
          name: 'Organisation name',
          key: 'organisation',
        },
        {
          name: 'Suburb',
          key: 'suburb',
        },
        {
          name: 'State',
          key: 'state',
        },
        {
          name: 'Date created',
          key: 'date_started',
        },
        {
          name: 'Last modified',
          key: 'last_modified_date',
        },
        {
          name: 'Last updated',
          key: 'last_updated_date',
        },
        {
          name: 'Status',
          key: 'status',
        },
        {
          name: 'Has bulk update',
          key: 'has_bulk_updates',
        },
        {
          name: 'Allocated to',
          key: 'allocated_user',
        },
      ]
    }
    return [
      {
        name: 'ID',
        key: 'id',
      },
      {
        name: 'Site name',
        key: 'name',
      },
      {
        name: 'Organisation name',
        key: 'organisation',
      },
      {
        name: 'Suburb',
        key: 'suburb',
      },
      {
        name: 'State',
        key: 'state',
      },
      {
        name: 'Last updated',
        key: 'last_updated_date',
      },
      {
        name: 'Status',
        key: 'status',
      },
      {
        name: 'Has bulk update',
        key: 'has_bulk_updates',
      },
      {
        name: 'Allocated to',
        key: 'allocated_user',
      },
    ]
  }
}

export const formatRowData = (
  recordType: string,
  item: SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>,
  userRole: 'Updater'|'Editor'|'Administrator',
): RecordPageType => {
  const recordId = fetchCorrectIdToDisplay(item.id, item.iss_id)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const lastUpdateDate = item.last_updated_date
    ? tsFormat(new Date(item.last_updated_date), timeZone, 'dd/MM/yyyy hh:mm aaa')
    : ''
  const lastModifiedDate = item.last_modified_date
    ? tsFormat(new Date(item.last_modified_date), timeZone, 'dd/MM/yyyy hh:mm aaa')
    : ''
  const rowData = {
    id: (<Link to={`/records/${recordType}/record/${recordId}`}>{recordId}</Link>),
    name: item.name || 'Record name',
    last_updated_date: lastUpdateDate,
    status:
     <SPUDRecordStatus
       status={item.status || 'published'}
       active={item.is_active} table issId={item.iss_id}
     />,
    record_type: item.record_type,
    is_active: !item.is_active,
    record_id: recordId,
    has_bulk_updates: item.has_bulk_updates && (
      <IconContainerWrapper
        icon={<FontAwesomeIcon icon={faInbox as IconProp} style={{ marginLeft: '3.1em' }} />}
        text="HAS BULK UPDATES"
      />
    ),
  }
  if (recordType === 'service' && item.site) {
    const siteID = fetchCorrectIdToDisplay(item.site?.id, item.site?.iss_id)
    if (userRole === 'Administrator') {
      return {
        ...rowData,
        date_started: formatDatetimeToLocal(item.date_started) || '',
        last_modified_date: lastModifiedDate,
        site: <span>{item.site.name} ({siteID}) | {item.site.is_active ? 'Active' : 'Deleted'}</span>,
        state: item.site?.location?.state,
        suburb: item.site?.location?.suburb,
      }
    }
    return {
      ...rowData,
      last_modified_date: lastModifiedDate,
      site: <span>{item.site.name} ({siteID}) | {item.site.is_active ? 'Active' : 'Deleted'}</span>,
      state: item.site?.location?.state,
      suburb: item.site?.location?.suburb,
    }
  } else if (recordType === 'organisation') {
    if (userRole === 'Administrator') {
      return {
        ...rowData,
        date_started: formatDatetimeToLocal(item.date_started) || '',
        last_modified_date: lastModifiedDate,
      }
    }
    return {
      ...rowData,
      last_modified_date: lastModifiedDate,
    }
  } else if (recordType === 'site') {
    if (userRole === 'Administrator') {
      return {
        ...rowData,
        suburb: item.location?.suburb,
        state: item.location?.state,
        date_started: formatDatetimeToLocal(item.date_started) || '',
        organisation: item.organisation?.name || 'No organisation set',
        last_modified_date: lastModifiedDate,
        allocated_user: item.allocated_user ? <SpudAvatar user={item.allocated_user}/> : null,
      }
    }
    return {
      ...rowData,
      suburb: item.location?.suburb,
      state: item.location?.state,
      organisation: item.organisation?.name || 'No organisation set',
      allocated_user: item.allocated_user ? <SpudAvatar user={item.allocated_user}/> : null,
    }
  }
  return {
    ...rowData,
    date_started: formatDatetimeToLocal(item.date_started) || '',
    allocated_user: item.allocated_user ? <SpudAvatar user={item.allocated_user}/> : null,
  }
}

export const formatReplacedFieldValue = (
  field: string,
  value: string | boolean | number,
  recordId: string | number,
  recordType: string,
) => {
  if (field === 'status') {
    return (
      <SPUDRecordStatus
        status={value as string}
        active={true}
        table
        issId={typeof recordId === 'number' ? recordId : null}
      />)
  } else if (field === 'id') {
    return (<Link to={`/records/${recordType}/record/${value}`}>{value}</Link>)
  } else {
    return value.toString()
  }
}

export const urlParamsToEmailFilterObj = (params: URLSearchParams): Record<string, unknown> => {
  const paramsObject: Record<string, unknown> = {}
  params.forEach((value, key) => {
    try {
      paramsObject[key] = JSON.parse(value)
    } catch (error) {
      paramsObject[key] = value
    }
  })
  return paramsObject
}

export const filterToUrlParams = (filters: {[x: string]: AdvancedFilterType}, params: URLSearchParams) => {
  Object.entries(filters).forEach(([key, filter]) => {
    if (filter.value !== undefined) {
      let filterValue = filter.value as FilterValueType | (string | number | null)[]
      if (typeof filterValue === 'string') {
        filterValue = normaliseSearchText(filterValue)
      }
      if (filter.searchOperator) {
        filterValue = JSON.stringify({ searchOperator: filter.searchOperator, value: filterValue })
      } else if (Array.isArray(filter?.value) && filterValue) {
        filterValue = JSON.stringify(filterValue)
      } else if (typeof filterValue === 'object') {
        let objectFilter = {}
        Object.entries(
          filterValue as unknown as
            {[x: string]: {searchOperator: SearchOperators, value: string} & Array<Option> & string})
          .forEach(([key, value]) => {
            if (value) {
              if (value.searchOperator) {
                objectFilter = {
                  ...objectFilter,
                  [key]: {
                    searchOperator: value.searchOperator,
                    value: normaliseSearchText(value.value),
                  },
                }
              } else {
                objectFilter = {
                  ...objectFilter,
                  [key]: value,
                }
              }
            }
          })
        filterValue = JSON.stringify(objectFilter)
      }
      params.append(key, filterValue as string)
    }
  })
  return params
}

export const urlParamsToFilterObj = (
  recordType: string,
  params: URLSearchParams,
  userRole: 'Updater'|'Editor'|'Administrator',
  pageType: 'dashboard'|'record-list'|'email-campaign',
) => {
  let filters = {}
  const recordFilterConfig = getFilterFields(recordType)
  const quickFilterPresets = fetchQuickFilterPresetByRole(userRole, pageType, recordType)
  params.forEach((value, name) => {
    const filterNameAndIndex = name.split('-')
    const filterName = filterNameAndIndex[0]
    let matchedFilter = recordFilterConfig.find(recordFilter => recordFilter.name === filterName)
    let formattedOption
    try {
      formattedOption = JSON.parse(value)
    } catch (e) {
      // Value is not JSON
      formattedOption = normaliseSearchText(value)
    }

    if (matchedFilter) {
      if (matchedFilter?.additionalControls && typeof formattedOption === 'object') {
        matchedFilter = {
          ...matchedFilter,
          ...formattedOption as {[x: string]: unknown},
        }
      } else if (matchedFilter?.defaultValue === false || matchedFilter?.defaultValue === true) {
        if (matchedFilter.defaultValue) {
          matchedFilter = { ...matchedFilter, defaultValue: 'true' }
        } else {
          matchedFilter = { ...matchedFilter, defaultValue: 'false' }
        }
        matchedFilter = { ...matchedFilter, value: normaliseSearchText(value) }
      } else if (matchedFilter.type === 'multiselect' && value) {
        matchedFilter = { ...matchedFilter, value: formattedOption }
      } else if (matchedFilter.type === 'customComponent') {
        let filterObjectValue: { [x: string]: unknown } = {}
        const formattedOption = JSON.parse(value)
        filterObjectValue = { ...filterObjectValue, ...formattedOption }
        matchedFilter = { ...matchedFilter, value: filterObjectValue as FilterValueType }
      } else {
        matchedFilter = { ...matchedFilter, value: normaliseSearchText(value) }
      }
      if (matchedFilter?.requiredFields) {
        matchedFilter = { ...matchedFilter, requiredFields: fetchRequiredFields(recordType) }
      }
      filters = { ...filters, [name]: matchedFilter }
    } else {
      const matchedQuickFilterPreset = quickFilterPresets.find(
        quickFilterPreset => quickFilterPreset.name === filterName,
      )
      if (matchedQuickFilterPreset) {
        filters = {
          ...filters,
          [name]: {
            type: 'toggle',
            label: matchedQuickFilterPreset.label,
            name: matchedQuickFilterPreset.name,
            value: normaliseSearchText(value),
            defaultValue: false,
          },
        }
      }
    }
  })
  return filters
}

export const loadCsvHeaders = (csvHeaders: Array<string>) => {
  const processedCsvHeading: Array<string> = []
  csvHeaders.forEach((heading: string) => {
    if (heading.includes('-')) {
      const [parentField, indexPlusField] = heading.split('-')
      const [index] = indexPlusField.split('.')
      if (MULTI_FIELDS.includes(parentField) && !processedCsvHeading.includes(`${parentField}-${index}`)) {
        processedCsvHeading.push(`${parentField}-${index}`)
      }
    } else {
      processedCsvHeading.push(heading)
    }
  })
  return processedCsvHeading
}
