
import API from 'axios'

export const getSPUDBulkUpdateEnvironment = (): string|null|undefined => {
  return window.SPUD_BULK_UPDATE_ENV
}

const NotificationApi = API.create({
  baseURL: window.NOTIFICATION_API_URL,
})

export default NotificationApi
