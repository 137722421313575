import React, { ReactNode, useEffect, useRef, useState } from 'react'

import {
  Content,
  Header,
  Section,
  Skeleton,
  Text,
  Row,
  Col,
  Card,
  Button,
  Link,
  Title,
  SpudTheme,
} from '@ix/ix-ui'
import {
  fetchCorrectIdToDisplay,
  fixAgeGroups,
  formatAddEditTitle,
  getPageType,
} from '../../helpers/record'
import AddEditRecord from '../../components/forms/AddEditRecord'
import { withContext } from '../../context/AppContext'
import { RouteComponentProps } from 'react-router'
import { AppContextType } from '../../context/AppContext.type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AxiosPromise } from 'axios'
import {
  fetchLinkedRecordTableMethod,
  generateAppendableValueObject,
} from './RecordDetails.service'
import { SPUDRecord, SPUDRecordWithData } from '../../../@types/SPUDRecord.type'
import SimpleSPUDRecordCard from '../../components/General/SimpleSPUDRecordCard'
import styled from 'styled-components'
import { formatDatetimeToLocal } from '../../helpers/datetime'
import { IsMobile } from '../../effects/IsMobile'
import { getScrollPosition } from '../../effects/scrollPosition'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { AgeGroupType, BulkServiceProviderUpdateType, spudAPI } from '../../services/spud.service'
import BulkUpdateRecordDetails from '../../components/forms/BulkUpdateRecordDetails'
import RightPanel from './ActionPanel/RightPanel'
import { notifyUserAboutFailedRequest } from '../../helpers/failedToFetchRecords'
import StyledServicePanel from './ActionPanel/StyledServicePanel'

type matchParams = {
  recordType?: string | undefined,
  action?: string,
  revisionId?: string,
}

type Props = RouteComponentProps<matchParams> & AppContextType & {
  revisionDetail : () => AxiosPromise,
}

const SpudRecordContent = styled(Content)`
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: auto;
  }
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2em;
  margin-bottom: 0.5em;
  color: #4F4F4F;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-left: 2em;
    font-size: 0.9rem;
  }
`

const SpudDateHeader = styled.h3`
  margin: 0;
  font-size: 15px;
  align-items: baseline;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    align-self: flex-start;
    font-size: 0.9rem;
  }
`

const DateText = styled(Text)`
  font-size: clamp(0.8rem, 1.2vw, 1rem);

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 9px;
    align-self: flex-start;
  }
`

const HeaderBarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: clip;
`

const RecordDetailsPage = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  background: #F4F6F9;
`

const RecordDetailRightPanelCard = styled(Card)<{sticky: boolean}>`
  height: 100%;
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  overflow-y: scroll;
  top: 0;
`
const MobileCol = styled(Col)`
  background-color: ${({ theme }) => theme.colors.colorBase};
  min-height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    position: absolute;
    top: 80px;
    right: 20px;
    bottom: 0;
    width: 45%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`

const RecordForm = styled(Col)`
  &.deleted-record-form {
    input, textarea, .spud-autocomplete, .form-geo-coverage,
    .form-opening-hours, .form-accreditations, .form-service-type {
      background-color: ${SpudTheme.colors.grayMedium};
      opacity: 0.9;
    }
    .status-indicator {
      background-color: ${SpudTheme.colors.grayMedium};
    }
  }
`

const SpudReturnButton = styled(Button)`
  display: flex;
  align-items: center;

@media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
  font-size: 1em;
  display: inline;
  line-height: 1.2;
  padding: 0.5em;
`

function RecordDetails (props: Props): React.ReactElement {
  const [recordData, setRecordData] = useState<
    SPUDRecordWithData<SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>>({
      iss_id: null,
      referral_enabled: false,
      has_external_comments: false,
      record_type: '',
      name: '',
      status: 'draft',
      abn: '',
      is_active: true,
      update: {
        id: null,
        data: {},
      },
      id: null,
      allocated_user: {
        first_name: '',
        last_name: '',
        id: null,
      },
      revision: null,
      is_externally_marked_for_deletion: false,
      last_updated_date: null,
      last_modified_date: null,
      date_started: null,
      data: { name: '', abn: null, date_last_updated: null, id: null, iss_id: null, is_active: true },
      has_bulk_updates: false,
    })
  const [isLoading, setIsLoading] = useState(false)
  const [linkedListIsLoading, setLinkedListIsLoading] = useState(false)
  const [ageGroups, setAgeGroups] = useState<{
      data: {
        results: Array<AgeGroupType>
        next: string | null
      }
    }>()
  const [linkedRecords, setLinkedRecords] = useState<Array<{
    id: string | number | null,
    name: ReactNode,
    recordType: string,
    status: string,
    iss_id: number | null,
    referral_enabled: boolean,
    is_active: boolean | undefined,
    datasets?: Array<string>,
  }>>([])
  const [bulkUpdateRecordChanges, setBulkUpdateRecordChanges] = useState<
    BulkServiceProviderUpdateType
  >()

  const [recordType, setRecordType] = useState<string>()
  const [isNewRecord, setIsNewRecord] = useState<boolean>(false)
  const [showRightPanel, setShowRightPanel] = useState(false)
  const [currentRightPanelTab, setCurrentRightPanelTab] = useState<
    'linkedRecords'|'comments'|'external_comments'>('linkedRecords')
  const [hasExternalComments, setHasExternalComments] = useState(false)
  const [resetBulkUpdateRecordChanges, setResetBulkUpdateRecordChanges] = useState(false)

  const isMobile = IsMobile()
  const scrollPosition = getScrollPosition()
  const rightPanelRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setRecordType(props.match.params.recordType)
  }, [])

  useEffect(() => {
    setIsNewRecord(recordData.id === null)
  }, [recordData.id])

  const fetchRecordDetails = async (
    revisionId: number | string): Promise<SPUDRecordWithData<SPUDSiteRecordDetails |
      SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>> => {
    const response = await props.recordDetail({ id: revisionId, recordType: props.match.params.recordType })
    if (response.data.has_external_comments) {
      setHasExternalComments(true)
    } else {
      setHasExternalComments(false)
    }
    return Object.assign(
      { },
      { data: response.data.update?.data || response.data.data || { name: '' } },
      response.data,
    )
  }

  const fetchLinkedRecords = async <
  T extends SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>(
    revisionDetail: SPUDRecord<T>): Promise<void> => {
    const linkedRecordMethod = fetchLinkedRecordTableMethod(props.match.params.recordType, revisionDetail)
    if (linkedRecordMethod?.method && linkedRecordMethod?.params) {
      setLinkedListIsLoading(true)
      try {
        const response = await linkedRecordMethod.method(linkedRecordMethod.params)
        setLinkedRecords(
          response.data.results.filter(item => item.id !== revisionDetail.id).map((res) => {
            const recordId = fetchCorrectIdToDisplay(res.id, res.iss_id)
            return {
              id: recordId,
              name: (
                <Link to={`/records/${res.record_type}/record/${recordId}`}>
                  {res.name || 'Unknown record name'}
                </Link>
              ),
              recordType: res.record_type,
              status: res.status || 'published',
              iss_id: res.iss_id,
              is_active: res.is_active,
              referral_enabled: res.referral_enabled,
              datasets: res.update?.data.datasets,
            }
          }),
        )
      } catch (error) {
        notifyUserAboutFailedRequest('Error fetching linked records')
      }
      setLinkedListIsLoading(false)
    }
  }

  const fetchRecord = async (recordId: number | string) => {
    setIsLoading(true)
    const revisionDetail = await fetchRecordDetails(recordId)
    const bulkUpdateChanges = await spudAPI.bulkUpdateChanges(props.match.params.recordType, recordId)
    // In the event that that age_groups are saved using their IDs instead of their names
    // we need to fetch the age groups and replace the IDs with the names
    if (props.match.params.recordType === 'service') {
      const ageGroups = await spudAPI.fetchAgeGroups({ recordType: 'service', limit: 15, offset: 0 })
      bulkUpdateChanges.data && bulkUpdateChanges.data.update_data.forEach((update) => {
        if ('age_groups' in update.data) {
          update.data.age_groups = fixAgeGroups(update.data.age_groups as Array<string>, ageGroups)
        }
      })
      setAgeGroups(ageGroups)
      if ('age_groups' in revisionDetail.data) {
        revisionDetail.data.age_groups = fixAgeGroups(revisionDetail.data.age_groups, ageGroups)
      }
      // Edge case where a service record is created via the bulk update form and the date_last_updated
      // is set against an external update that is not reflected in the record data
      if (!revisionDetail.data.date_last_updated && revisionDetail.last_updated_date) {
        revisionDetail.data.date_last_updated = revisionDetail.last_updated_date
      }
    }
    setRecordData(revisionDetail)
    fetchLinkedRecords(revisionDetail)
    setResetBulkUpdateRecordChanges(true)
    setBulkUpdateRecordChanges(bulkUpdateChanges.data)
    setResetBulkUpdateRecordChanges(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.match.params.revisionId) {
      if (props.match.params.recordType !== recordType) {
        // When navigating a different record type without reloading the page,
        // we need to rest the bulkUpdateRecordChanges
        // to allow for new changes to be loaded
        setBulkUpdateRecordChanges(undefined)
        setRecordType(props.match.params.recordType)
      }
      setRecordData({
        iss_id: null,
        referral_enabled: false,
        has_external_comments: false,
        record_type: '',
        name: '',
        status: 'draft',
        abn: '',
        is_active: true,
        update: {
          id: null,
          data: {},
        },
        id: null,
        allocated_user: {
          first_name: '',
          last_name: '',
          id: null,
        },
        revision: null,
        last_updated_date: null,
        last_modified_date: null,
        date_started: null,
        data: { name: '', abn: null, date_last_updated: null, id: null, iss_id: null, is_active: true },
        has_bulk_updates: false,
        is_externally_marked_for_deletion: false,
      }) // Reset record data to prevent old data from showing
      fetchRecord(props.match.params.revisionId)
    }
  }, [props.location.pathname, props.match.params.revisionId])

  /**
   * Generates the Heading 1 of the record details page
   */
  const generateTitle = () => {
    if (props.match.params.revisionId || recordData.id) {
      if (isLoading) {
        return <Skeleton/>
      } else {
        return recordData.data?.name || 'Record Name'
      }
    } else {
      return formatAddEditTitle(props.match.params.recordType, props.match.params.action)
    }
  }

  const displayLinkedOrganisationOrSite = (
    title: string,
    linkedRecord: SPUDSiteRecordDetails | SPUDOrganisationRecordDetails,
    recordType: string,
  ): React.ReactElement | null => {
    if (!isLoading && linkedRecord && 'name' in linkedRecord) {
      return (
        <HeaderBarSection>
          <SimpleSPUDRecordCard
            recordType={recordType}
            title={
              <Link to={`/records/${recordType}/record/${
                fetchCorrectIdToDisplay(linkedRecord.id, linkedRecord.iss_id)
              }`}
              onClick={() => {
                setCurrentRightPanelTab('linkedRecords')
              }}
              >
                {linkedRecord.name}
              </Link>
            }
            recordId={linkedRecord.id}
            issId={linkedRecord.iss_id}
            active={linkedRecord.is_active}
            color='#c7c7c7'
            referralEnabled={linkedRecord.referral_enabled}
          />

          <span style={{ margin: '0 2em' }}>
            <FontAwesomeIcon size='2x' color='#8c9bad' icon={faChevronRight as IconProp} />
          </span>
        </HeaderBarSection>
      )
    }
    return isLoading
      ? <HeaderBarSection>
        <Skeleton />
        <span style={{ margin: '0 2em' }}>
          <FontAwesomeIcon size='2x' color='#8c9bad' icon={faChevronRight as IconProp} />
        </span>
      </HeaderBarSection>
      : null
  }

  const shouldRenderPanel = (): boolean => {
    let renderPanel
    if (isMobile && showRightPanel) {
      renderPanel = true
    } else {
      renderPanel = !(isMobile && !showRightPanel)
    }
    return renderPanel
  }

  const disabled = !!(recordData.id && !recordData.is_active)

  const StyledHeader = styled(Header)`
    margin: 0 1em 1em;
    padding: 0.5em 0;
    minHeight: auto;
    &.header-status-published {
      background:linear-gradient(315deg,#25AF06,#fff, #fff, #fff);
      border:2px solid #25AF06;
    }
    &.header-status-pending {
      background:linear-gradient(315deg,orange,#fff, #fff, #fff);
      border:2px solid orange;
    }
    &.header-status-draft {
      background:linear-gradient(315deg, #478fe5, #fff, #fff, #fff);
      border:2px solid #478fe5;
    }
    &.header-status-deleted {
      background:linear-gradient(315deg, #d90e0e, #fff, #fff, #fff);
      border:2px solid #d90e0e;
    }
  `

  return (
    <RecordDetailsPage>
      <SpudRecordContent>
        <StyledHeader
          className={recordData.is_active ? `header-status-${recordData.status}` : 'header-status-deleted'}
        >
          <Row style={{ gap: '2em' }}>
            <Col direction='row' justify='flex-start' flex={2}>
              <SpudReturnButton light style={{ alignSelf: 'center', marginLeft: '1em' }} onClick={() => {
                props.history.push(`/records/${props.match.params.recordType}`)
              }}
              >
                <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                {' '}
                {getPageType(props.match.params.recordType)} List
              </SpudReturnButton>
              <div style={{ display: 'flex', flexDirection: 'row' }}>

                {recordType !== 'service' && recordData?.organisation && displayLinkedOrganisationOrSite(
                  'Organisation',
                  recordData.organisation as SPUDOrganisationRecordDetails,
                  'organisation',
                )}

                {recordType === 'service' && recordData?.site && displayLinkedOrganisationOrSite(
                  'Site',
                  recordData.site,
                  'site',
                )}

                <HeaderBarSection>
                  <SimpleSPUDRecordCard
                    recordType={recordType}
                    title={generateTitle()}
                    recordId={recordData.id}
                    status={recordData.status || 'published'}
                    issId={recordData.iss_id}
                    active={recordData.is_active}
                    referralEnabled={recordData.referral_enabled}
                  />
                </HeaderBarSection>
                {recordData.date_started && !isNewRecord && (
                  <DateWrapper>
                    <SpudDateHeader>Date created</SpudDateHeader>
                    <DateText>{formatDatetimeToLocal(recordData.date_started)}</DateText>
                  </DateWrapper>
                )}

              </div>
            </Col>
            {recordType === 'site' &&
              <Col align='flex-end' justify='center' style={{ flex: '0 1 auto' }}>
                <Row>
                  <Col align='flex-end' justify='center'>
                    <Title level={4} marginTop='0'>
                      Allocated to:
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col align='flex-end' justify='center'>
                    {recordData.allocated_user
                      ? `${recordData.allocated_user.first_name} ${recordData.allocated_user.last_name}`
                      : 'Unallocated'
                    }
                  </Col>
                </Row>
              </Col>}
          </Row>
        </StyledHeader>
        <Section style={{ padding: '0 0 0 1em' }}>
          <Row>
            {bulkUpdateRecordChanges?.bulk_update_record?.contact_name &&
              <BulkUpdateRecordDetails
                bulkUpdateRecord={bulkUpdateRecordChanges?.bulk_update_record}
                isMarkedForDeletion={recordData?.is_externally_marked_for_deletion}
              />}
          </Row>
          <Row style={{ height: '100%' }}>
            <RecordForm className={recordData.is_active ? 'record-form' : 'deleted-record-form'}>
              {recordType && (
                <AddEditRecord
                  recordType={recordType}
                  appendValue={generateAppendableValueObject(recordData, recordType)}
                  formData={recordData}
                  bulkUpdateChanges={bulkUpdateRecordChanges}
                  isNewRecord={isNewRecord}
                  loadingForm={isLoading}
                  recordId={recordType === props.match.params.recordType ? props.match.params.revisionId : null}
                  action={props.match.params.action}
                  linkedRecords={linkedRecords}
                  onSaveCallback={(response, refetch) => {
                    setIsNewRecord(false)
                    if (props.match.params.recordType === recordType) {
                      let recordId = fetchCorrectIdToDisplay(recordData?.id, response?.revision.iss_id)
                      if (response) {
                        recordId = fetchCorrectIdToDisplay(
                          response?.revision?.record || response?.revision.record_id || response?.id,
                          response?.revision.iss_id,
                        )
                      }
                      props.history.push(
                        `/records/${props.match.params.recordType}/record/${
                          recordId
                        }`)
                    } else {
                      setRecordType(props.match.params.recordType)
                      props.match.params.revisionId && fetchRecord(props.match.params.revisionId)
                      fetchLinkedRecords(recordData)
                    }
                    if (refetch) {
                      props.match.params.revisionId && fetchRecord(props.match.params.revisionId)
                    }
                  }}
                  onShowRightPanel={(show) => setShowRightPanel(show)}
                  resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
                  setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
                  ageGroups={ageGroups}
                  {...props}
                />
              )}
            </RecordForm>
            {shouldRenderPanel() && <MobileCol xxl={4} xl={6} sm={7} ref={rightPanelRef}>
              <RecordDetailRightPanelCard
                style={{
                  width: !(scrollPosition > 100) ? 'auto' : rightPanelRef?.current?.clientWidth || '100%',
                  top: scrollPosition > 100 ? '80px' : '0',
                }}
                padding='25px 15px'
                marginTop='0'
                sticky={scrollPosition > 100}
              >
                <Row>
                  <StyledServicePanel
                    hasExternalComments={hasExternalComments}
                    recordType={recordType}
                    recordId={props.match.params.revisionId}
                    onChange={(tab) => (setCurrentRightPanelTab(tab))}
                    currentRightPanelTab={currentRightPanelTab}
                  />
                </Row>
                <Row>
                  <Col padding='0'>
                    <RightPanel
                      currentRightPanelTab={currentRightPanelTab}
                      recordType={recordType as string}
                      setRecordData={setRecordData}
                      isLoading={isLoading}
                      recordId={props.match.params.revisionId as string}
                      disabled={disabled}
                      linkedRecords={linkedRecords}
                      setHasExternalComments={setHasExternalComments}
                      linkedListIsLoading={linkedListIsLoading}
                      recordData={recordData}
                      setIsNewRecord={setIsNewRecord}
                      setRecordType={setRecordType}
                      navigateTo={props.history.push}
                    />
                  </Col >
                </Row>
              </RecordDetailRightPanelCard>
            </MobileCol >}
          </Row>
        </Section>
      </SpudRecordContent>
    </RecordDetailsPage>
  )
}

export default withContext(RecordDetails)
