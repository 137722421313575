import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import BulkUpdateFindReplaceWidget from './BulkUpdateFindReplaceWidget'
import { flattenSelectedRows } from '../ListView.service'
import { bulkUpdate } from '../../../../services/spud.service'
import { Spinner, Row, Col, Title } from '@ix/ix-ui'
import { SendMessage } from 'react-use-websocket'
import { withContext } from '../../../../context/AppContext'
import { SPUDWebSocketMessage } from '../../../../context/AppContext.type'
import pluralize from 'pluralize'

type BulkUpdateProps = {
  selectedRows: {[x: number]: {record_id: string | number, name: string}},
  recordType: string
  dismissPopup: () => void,
  selectedAllRows: boolean,
  total: number,
  sendMessage: SendMessage,
  lastMessage: SPUDWebSocketMessage,
  websocketConnected: string,
  backgroundTaskResults: SPUDWebSocketMessage<{
    updated: number,
    failed: number,
    action: string
  }>,
}

const BulkUpdateCard = styled.div<{loading: boolean}>`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  top: 30%;
  min-width: ${props => props.loading ? '25%' : '40%'};
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const BulkUpdateHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const BulkUpdateTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
`

const BulkUpdateCloseButtonContainer = styled.div`
  padding: 5px;
`
const BulkUpdateCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%;
  }
`

const BulkUpdateContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
`

const BulkUpdateContent = styled.div`
  height: 100%;
  width: 100%;
`

const BulkUpdateControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.colors.accentColor};
  padding: 1em;
`

const BulkUpdateControlButton = styled.button<{confirm: boolean}>`
  font-weight: bold;
  border: none;
  background-color: ${props => props.confirm ? props.theme.dialogConfirmColour : props.theme.colors.active};
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

const BulkUpdateConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

function BulkUpdate (
  {
    selectedRows,
    recordType,
    dismissPopup,
    sendMessage,
    backgroundTaskResults,
  }: BulkUpdateProps) {
  const [maxWidth, setMaxWidth] = useState<string | number>('100%')
  const [numberOfRows, setNumberOfRows] = useState(0)
  const [findFromFields, setFindFromFields] = useState<{[x: string]: unknown}>({})
  const [replaceWithFields, setReplaceWithFields] = useState<{[x: string]: unknown}>({})
  const [progressStep, setProgressStep] = useState<'config'|'loading'|'review'>('config')

  const bulkUpdateCardRef = useRef<HTMLDivElement>(null)
  const actionType = backgroundTaskResults.message.action

  useEffect(() => {
    setNumberOfRows(Object.keys(selectedRows).length)
    bulkUpdateCardRef.current && setMaxWidth(bulkUpdateCardRef.current?.offsetWidth)
    setProgressStep('config')
  }, [])

  return <BulkUpdateCard aria-label='update-records' ref={bulkUpdateCardRef} loading={progressStep === 'loading'}>
    {progressStep !== 'loading' && <BulkUpdateHeader>
      <BulkUpdateTitle>
        <div>
          Bulk {actionType.toLowerCase()} {progressStep === 'review' ? 'results' : 'config'}
        </div>
        <div>
          {pluralize(recordType, numberOfRows, true)} selected
        </div>
      </BulkUpdateTitle>
      <BulkUpdateCloseButtonContainer>
        <BulkUpdateCloseButton
          onClick={() => {
            dismissPopup()
          }}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </BulkUpdateCloseButton>
      </BulkUpdateCloseButtonContainer>
    </BulkUpdateHeader>}
    <BulkUpdateContentContainer>
      <BulkUpdateContent>
        {progressStep === 'config' &&
          <BulkUpdateFindReplaceWidget
            recordType={recordType}
            findFromCallback={(findFromFields) => {
              setFindFromFields(findFromFields)
            }}
            replaceWithCallback={(replaceWithFields) => {
              setReplaceWithFields(replaceWithFields)
            }}
            maxWidth={maxWidth}
          />}
        {progressStep === 'loading' && <Row>
          <Col>
            <Row>
              <Col>
                <Spinner type='circleSpinner' backgroundColor='#fff' size='sm' foregroundColor='#000'/>
              </Col>
            </Row>
            <Row>
              <Col align='center'>
                <Title level={4}>
                  Updating {pluralize(recordType, numberOfRows, true)}
                </Title>
              </Col>
            </Row>
          </Col>
        </Row>}
      </BulkUpdateContent>
    </BulkUpdateContentContainer>
    {progressStep !== 'loading' && <BulkUpdateControlBar>
      <BulkUpdateConfirmButtonGroup>
        {progressStep === 'config' &&
          <BulkUpdateControlButton
            confirm={true}
            onClick={async () => {
              sendMessage(JSON.stringify({
                type: 'bulk_update',
                message: 'update',
              }))
              setProgressStep('loading')
              // eslint-disable-next-line camelcase
              const record_ids: Array<string | number> = flattenSelectedRows(
                selectedRows).map(record => record.record_id)
              /* eslint-disable camelcase */
              const payload = {
                find: findFromFields,
                replace: replaceWithFields,
                record_ids: record_ids,
                record_type: recordType,
                bulk_action: 'update',
              }
              /* eslint-enable camelcase */
              try {
                await bulkUpdate(payload)
                dismissPopup()
              } catch (e) {
                setProgressStep('config')
              }
            }}
          >
            Update
          </BulkUpdateControlButton>}
      </BulkUpdateConfirmButtonGroup>
    </BulkUpdateControlBar>}
  </BulkUpdateCard>
}

export default withContext(BulkUpdate)
