import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../helpers/auth'
import {
  faChartLine,
  faTasks,
  faCog,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faHome,
  faBuilding,
  faUsersGear,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

import {
  Badge,
  Collapse,
  Dropdown,
  Footer,
  Logo,
  MenuItem,
  Sidebar,
  Text,
  Button,
  Row,
  Col,
} from '@ix/ix-ui'

import SpudAvatar from './SPUDAvatar'
import { withContext } from '../context/AppContext'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { AppContextType } from '../context/AppContext.type'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { useKeycloak } from '@react-keycloak/web'

const DismissReviewBackgroundTaskButton = styled.button`
  background: none;
  border: none;
  color: #0e0e0e;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

const SPUDFooterDropdownWrapper = styled.div <{isCollapsed: boolean}>`
  .dd-list {
    text-align: center;
  }


  left: -5px;
  ${props => props.isCollapsed && css`
    left: 5px;
    .open .dd-list {
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      border: 1px solid black;
    }
    .ddHeader {
      padding: 0 !important;
      svg {
        display: none;
      }
    }
    .ddHeaderTitle {
      overflow: visible;
    }
  `}


  .open .ddHeader {
    background-color: #091523;
    border-radius: 3px 3px 0 0;

     &:hover {
      background-color: #050e17;
  }
`

const UserDetailsButton = styled(NavLink)`
  background: #F4F6F9;
  border: none;
  color: #06111E;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  text-decoration: none;

  span {
    font-size: 12px;
    color: #06111E;
  }

  :hover {
    background-color: ${props => props.theme.colors.tertiary};
    color: #F4F6F9;

    span {
      color: #F4F6F9;
      text-decoration: none;
    }
  }
`

const LogoutButton = styled.button`
  background: #F4F6F9;
  border: none;
  color: #06111E;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 15px;

  span {
    font-size: 12px;
    color: #06111E;
  }

  :hover {
    background-color: ${props => props.theme.colors.tertiary};
    color: #F4F6F9;

    span {
      color: #F4F6F9;
      text-decoration: none;
    }
  }
`

const WebsocketResultContainer = styled.div`
  padding: 1em;
  background-color: #fff;
  color: #fff;
  border-radius: 3px;
`

const SubmenuIcon = styled.div`
  position: absolute;
  right: 3px;
  top: 11px;
  font-size: 12px;
`
const MenuItemContainer = styled.div<{isCollapsed: boolean}>`
     ${props => !props.isCollapsed && css`  margin-left: 1em`};
`

const CollapserButton = styled.button<{isCollapsed: boolean}>`
    width: 20px;
    height: 20px;
    background: #f0f0f0;
    align-self: flex-end;
    ${props => props.isCollapsed && css`
        margin-top: 1em;
    `}
    border-radius: 50%;
`

type SideNavProps = AppContextType & {
  onClearFailedMessages: () => void,
}

const SideNav = (props: SideNavProps): React.ReactElement => {
  const { user, userRole } = useAuth()
  const { keycloak } = useKeycloak()
  const [dashboardCount, setDashboardCount] = useState(0)
  const [displayWebsocketResults, setDisplayWebSocketResults] = useState(false)
  const [hasFailedResults, setHasFailedResults] = useState(false)
  const [updatedCount, setUpdatedCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [websocketAction, setWebsocketAction] = useState('')
  const [websocketActionInProgress, setWebsocketActionInProgress] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed)
  }

  // This is a workaround to fix the z-index issue with the footer and the sidebar
  const elements = document.querySelectorAll('[class^="Sidebar__StyledSidebar"]')
  elements.forEach((element) => {
    const footerElm = (element as HTMLElement).getElementsByTagName('footer').item(0)
    if (isCollapsed) {
      ((element as HTMLElement).style.zIndex = '1')
      // If the footer is present, we need to adjust the padding
      if (footerElm) {
        footerElm.style.padding = '0'
      }
    } else if (!isCollapsed && footerElm) {
      footerElm.style.padding = '15px 20px'
    }
  })

  useEffect(() => {
    const websocketMessage = props.lastMessage as {
      type: string,
      message: {
        action: string,
        failed: boolean
        updated: number
      }
    }
    if (websocketMessage) {
      if (websocketMessage.type === 'update_in_progress') {
        setWebsocketActionInProgress(true)
      }
      if (websocketMessage?.type === 'completed') {
        setWebsocketActionInProgress(false)
        setDisplayWebSocketResults(true)
        setWebsocketAction(websocketMessage.message.action)
        if (websocketMessage.message.failed) {
          websocketMessage.message.updated && setUpdatedCount(websocketMessage.message.updated)
          setHasFailedResults(true)
        } else {
          setHasFailedResults(false)
        }
      } else {
        setWebsocketAction('')
        setDisplayWebSocketResults(false)
        setHasFailedResults(false)
      }
    }
  }, [props.lastMessage])

  const logoutUser = () => {
    if (keycloak?.authenticated) {
      sessionStorage.clear()
      keycloak.logout()
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    const getCount = async (): Promise<void> => {
      setTimeout(async () => {
        try {
          const dueForUpdateFilter = new URLSearchParams()
          dueForUpdateFilter.append('due_for_update-0', 'true')
          dueForUpdateFilter.append('allocated-0', 'true')
          setLoading(true)
          const response = await props
            .countRecords(
              {
                recordType: 'site',
                offset: 0,
                limit: 1,
                ordering: 'pk',
              },
              userRole === 'Administrator' ? null : dueForUpdateFilter,
              abortController,
            )
          setLoading(false)
          setDashboardCount(response.data.count)
        } catch (error) {
          console.error(error)
          // If it's not an aborted request we stop it from loading
          if (!axios.isCancel(error)) {
            setLoading(false)
          }
        }
      }, 0)
    }
    if (!dashboardCount) {
      getCount()
    }
  }, [])

  const Profile = () => (
    <div>
      <SpudAvatar user={{
        first_name: user.given_name,
        last_name: user.family_name,
        id: user.id,
      }}
      />
      <Text style={{ lineHeight: '40px' }}>{user?.name || 'SPUD User'}</Text>
    </div>
  )

  const [menuRecordsOpen, setMenuRecordsOpen] = useState(false)
  const [menuAdminOpen, setMenuAdminOpen] = useState(false)

  const handleMenuRecordsClick = () => {
    window.scrollTo({ top: 0 })
    setMenuRecordsOpen(!menuRecordsOpen)
    setMenuAdminOpen(false)
  }

  const handleMenuAdminClick = () => {
    setMenuAdminOpen(!menuAdminOpen)
    setMenuRecordsOpen(false)
  }

  const handleNormalClick = () => {
    setMenuRecordsOpen(false)
    setMenuAdminOpen(false)
    window.scrollTo(0, 0)
  }

  const SPUDMenuItem = ({ toUrl, icon, label, subMenu }: {
    toUrl: string,
    icon: IconProp,
    label: string,
    subMenu: boolean,
  }) => (
    <MenuItem submenu={subMenu} title={label}>
      <NavLink
        to={toUrl}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
        <Collapse>{label}</Collapse>
      </NavLink>
    </MenuItem>
  )

  const DropdownMenu = () => (
    <>
      <UserDetailsButton to="/user"aria-label="user-details" >
        <Text style={{ whiteSpace: 'nowrap' }}>User Details</Text>
      </UserDetailsButton>

      <hr style={{ width: '100%', height: '1px', backgroundColor: '#000', border: 'none', margin: '0' }} />

      <LogoutButton
        onClick={() => logoutUser()}
        aria-label="logout"
      >
        <Text style={{ whiteSpace: 'nowrap' }}>Sign Out</Text>
      </LogoutButton>
    </>
  )

  return (

    <Sidebar fixed collapsed={isCollapsed}>
      <Row align='center'>
        {!isCollapsed && <Col>
          <Logo inverse compact />
        </Col>}
        <Col>
          <CollapserButton onClick={toggleSidebar} isCollapsed={isCollapsed} data-testid='sidenavCollapserButton'>
            <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft}
              style={{
                height: '14px',
                width: '6px',
                verticalAlign: 'top',
                color: 'grey',
                alignItems: 'center',
                borderRadius: '50%',
              }}
            />
          </CollapserButton>
        </Col>
      </Row>
      <MenuItemContainer isCollapsed={isCollapsed}>
        <MenuItem title="Dashboard">
          <NavLink to="/Dashboard" onClick={handleNormalClick}>
            <FontAwesomeIcon icon={faChartLine as IconProp} fixedWidth/>
            <Collapse>
                  Dashboard
              <Badge content={loading ? 'loading' : dashboardCount} />
            </Collapse>
          </NavLink>
        </MenuItem>
      </MenuItemContainer >

      {isCollapsed && (
        <>
          <SPUDMenuItem subMenu={false} toUrl='/records/organisation' icon={faBuilding} label='Organisations' />
          <SPUDMenuItem subMenu={false} toUrl='/records/site' icon={faHome} label='Sites'/>
          <SPUDMenuItem subMenu={false} toUrl='/records/service' icon={faUsersGear} label='Services'/>
          <SPUDMenuItem subMenu={false} toUrl='/administration/emailcampaign' icon={faEnvelope} label='Email campaign'/>
        </>
      )}

      { !isCollapsed && <MenuItemContainer isCollapsed={isCollapsed}>
        <MenuItem title="Records">
          <NavLink to="/records/site" onClick={handleMenuRecordsClick}>
            <FontAwesomeIcon icon={faTasks as IconProp} fixedWidth/>
            <Collapse>Records</Collapse>
            <SubmenuIcon>
              {menuRecordsOpen
                ? <FontAwesomeIcon icon={faChevronUp as IconProp} fixedWidth />
                : <FontAwesomeIcon icon={faChevronDown as IconProp} fixedWidth />
              }
            </SubmenuIcon>
          </NavLink>
        </MenuItem>
        {menuRecordsOpen &&
          <>
            <SPUDMenuItem subMenu={true} toUrl='/records/site' icon={faHome} label='Sites'/>
            <SPUDMenuItem subMenu={true} toUrl='/records/organisation' icon={faBuilding} label='Organisations'/>
            <SPUDMenuItem subMenu={true} toUrl='/records/service' icon={faUsersGear} label='Services'/>
          </>
        }
        {userRole === 'Administrator' && (
          <MenuItem title="Administrator">
            <NavLink to="/administration" onClick={handleMenuAdminClick}>
              <FontAwesomeIcon icon={faCog as IconProp} fixedWidth/>
              <Collapse>Administration</Collapse>
              <SubmenuIcon>
                {menuAdminOpen
                  ? <FontAwesomeIcon icon={faChevronUp as IconProp} fixedWidth />
                  : <FontAwesomeIcon icon={faChevronDown as IconProp} fixedWidth />
                }
              </SubmenuIcon>
            </NavLink>
          </MenuItem>
        )}
        {menuAdminOpen && (
          <>
            <SPUDMenuItem
              subMenu={true}
              toUrl="/administration/emailcampaign"
              icon={faEnvelope}
              label='Email campaign'
            />
          </>
        )}
      </MenuItemContainer >}

      <Footer isCollapsed={isCollapsed} aria-label='Logged in user'>
        {(displayWebsocketResults || websocketActionInProgress) &&
            <WebsocketResultContainer>
              <Row>
                <Col direction='row' justify='flex-end'>
                  <DismissReviewBackgroundTaskButton onClick={() => setDisplayWebSocketResults(false)}>
                  </DismissReviewBackgroundTaskButton>
                </Col>
              </Row>
              <Row>
                <Col>
                  {displayWebsocketResults && <Text padding="0 10px 0 0">
                    {hasFailedResults
                      ? websocketAction === 'Imported' && !updatedCount
                        ? 'Failed to import (see errors)'
                        : `${websocketAction} (completed with errors)`
                      : `${websocketAction} (completed)`}
                    <Button active onClick={() => props.setPopupType('review')}>
                      See results
                    </Button>
                  </Text>}
                </Col>
              </Row>
              {websocketActionInProgress && <span style={{ color: 'black' }}>loading...</span>}
            </WebsocketResultContainer>}
        <SPUDFooterDropdownWrapper isCollapsed={isCollapsed}>
          {isCollapsed && (
            <Dropdown
              up
              dark
              block
              title={
                <SpudAvatar
                  user={{
                    first_name: user.given_name,
                    last_name: user.family_name,
                    id: user.id,
                  }}
                />
              }
              width='auto'
            >
              <DropdownMenu />
            </Dropdown>
          )}
          {!isCollapsed && (
            <Dropdown
              up
              dark
              block
              title={<Profile />}
              width="100%"
            >
              <DropdownMenu />
            </Dropdown>
          )}
        </SPUDFooterDropdownWrapper>
      </Footer></Sidebar>
  )
}

export default withContext(SideNav)
