import React from 'react'
import { Row, Col, Title } from '@ix/ix-ui'
import SPUDAutoComplete from '../../../components/General/SPUDAutoComplete'
import { Option } from '../../../components/forms/AddEditRecord.type'
import {
  AdvancedFilterType,
  LocationFilterValueType,
  SearchOperators,
} from '../RecordFilter.service'
import TextInputWithSearchOperators from './TextInputWithSearchOperators'
import SPUDLocationAutocomplete from '../../../components/General/SPUDLocationAutocomplete'
import { MultiValue } from 'react-select'
import { SPUDInputField } from '../../../helpers/record'
import styled from 'styled-components'

export type LocationFilterProps = {
  recordType: string,
  onChangeCallback: (
    filterField: AdvancedFilterType<LocationFilterValueType>,
    value: LocationFilterValueType,
  ) => void
  filterFieldProperties: {
    postcode: AdvancedFilterType<string>
    suburb: AdvancedFilterType<Array<Option>>
    suburbText: AdvancedFilterType<{
      searchOperator: SearchOperators,
      value: string
    }>
    state: AdvancedFilterType<Option>
  },
  filterField: AdvancedFilterType<LocationFilterValueType>
}

const customStyle = {
  control: () => ({
    border: '1px solid #3a8ae8',
    backgroundColor: '#f6f6f6',
    borderRadius: 3,
    display: 'flex',
    padding: 1,
    marginTop: 1,
    textAlign: 'left',
  }),
  menuList: () => ({
    textAlign: 'left',
  }),
}

const LocationFilterHr = styled.hr`
  width: 100%;
  height: 2px;
`

function LocationFilter (
  {
    recordType,
    onChangeCallback,
    filterFieldProperties,
    filterField,
  }: LocationFilterProps) {
  const stateOptions = [
    { id: 'any', name: 'Any state' },
    { id: 'ACT', name: 'ACT' },
    { id: 'NSW', name: 'NSW' },
    { id: 'NT', name: 'NT' },
    { id: 'QLD', name: 'QLD' },
    { id: 'SA', name: 'SA' },
    { id: 'TAS', name: 'TAS' },
    { id: 'VIC', name: 'VIC' },
    { id: 'WA', name: 'WA' },
  ]

  return (
    <div>
      <Row>
        <Col>
          <SPUDLocationAutocomplete
            label={filterFieldProperties.suburb.label}
            placeholder={filterFieldProperties.suburb.label}
            required={false}
            highlight={false}
            disabled={false}
            multiValue={filterField.value?.suburb as Array<Option>}
            onMultiChange={(value: MultiValue<Option>) => {
              onChangeCallback(filterField, {
                ...filterField.value,
                suburb: value as Array<Option>,
              })
            }}
            multi={true}
            customStyle={customStyle}
            externalForm={false}
          />
        </Col>
      </Row>
      <Row style={{ margin: '1em 0' }}>
        <Col flex={2}>
          <LocationFilterHr />
        </Col>
        <Col>
          And/Or
        </Col>
        <Col flex={2}>
          <LocationFilterHr />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInputWithSearchOperators<{
            searchOperator: SearchOperators,
            value: string
          }>
            filterField={filterFieldProperties.suburbText}
            valueOverride={filterField.value?.suburbText}
            onChangeCallback={(
              filter: AdvancedFilterType<{
                searchOperator: SearchOperators,
                value: string
              }>,
              filterValue: string,
              searchOperator,
            ) => {
              if (searchOperator) {
                onChangeCallback(filterField, {
                  ...filterField?.value,
                  suburbText: {
                    value: filterValue as string,
                    searchOperator: searchOperator,
                  },
                })
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SPUDInputField
            type={filterFieldProperties.postcode.type}
            placeholder={filterFieldProperties.postcode.label}
            label={<Title level={5} marginTop='5px'>{filterFieldProperties.postcode.label}</Title>}
            value={filterField.value?.postcode}
            highlight={false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeCallback(filterField, {
                ...filterField?.value,
                postcode: event.target.value,
              })
            }
            fullwidth
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SPUDAutoComplete
            customStyleOverride={customStyle}
            label="State"
            options={stateOptions}
            selectedOption={filterField.value?.state as Option}
            isMulti={false}
            recordType={recordType}
            fieldName={filterFieldProperties.state.name}
            formatDisplayName={filterFieldProperties.state?.formatDisplayName}
            disabled={false}
            customIdentifier='id'
            isClearable={false}
            onChange={(option) => {
              onChangeCallback(filterField, {
                ...filterField.value,
                state: option as Option,
              })
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default LocationFilter
